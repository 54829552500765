import { Watts } from "./general";
import { PhysicalPlantId } from "./plant";

export type HHMM = string;
export type Instruction = "on";
export type AccessoryType = "smartPlug" | "evCharger" | "smartContactor";
export type AccessoryStatus =
  | "on"
  | "off"
  | "charging"
  | "discharging"
  | "connected"
  | "disconnected"
  | "error";

export enum OCPPChargeMode {
  Off = "off",
  ChangeNow = "on",
  Schedule = "as-scheduled",
}

export type AccessoryId = number;

export type AccessoryData = {
  time: number;
  retrievedTime: number;
  powerCumulative?: number;
  power?: Watts;
  current?: number;
  voltage?: number;
  frequency?: number;
  totalConsumptionToday?: number;
  averageConsumption7Days?: number;
  totalConsumption7Days?: number;
  averageConsumptionMonthly?: number;
  totalConsumptionMonthly?: number;
};

/** @deprecated */
export type ZappiData = {
  chargeMode: "fast" | "eco" | "ecoPlus" | "stop";
  lastSessionAddedKwh?: number;
  minimumGreenLevel: number;
  commandTimer: number;
  manualBoost: {
    active: boolean;
    boostKwh?: number;
  };
  smartBoost: {
    active: boolean;
    boostKwh?: number;
    startHour?: number;
    startMinute?: number;
  };
  timedBoost: {
    active: boolean;
    boostKwh?: number;
    startHour?: number;
    startMinute?: number;
  };
};

export interface OCPPStatus {
  userChargingMode: OCPPChargeMode,
  realtimeChargerState: string,
  chargeLimit?: number | null,
}

export type ServerAccessoryData = {
  status: AccessoryStatus;
  message?: string;
  currentData?: AccessoryData;
  /**@deprecated */
  zappi?: ZappiData;
  ocpp?: OCPPStatus;
};

type ToggleCommandResponse = object;

/** @deprecated */
export type ZappiCommand = {
  chargeMode?: "fast" | "eco" | "ecoPlus" | "stop";
  minimumGreenLevel?: number;
  boostMode?: {
    boostType: "manualBoost" | "smartBoost" | "timedBoost" | "stop";
    boostKwh?: number;
    startHour?: number;
    startMinute?: number;
  };
};

export interface AccessoryOverrideCommand {
  overrideDirection: boolean | null;
  expiryDurationMillis?: number;
  overrideDetails: object;
}

export interface OCPPCommand {
  chargingMode?: OCPPChargeMode;
  chargeLimit?: number;
  special?: "reboot" | "soft-reset" | "check-active";
  /** @deprecated */
  action?: "RemoteStartTransaction" | "RemoteStopTransaction";
}

export type AccessoryCommandRequest = {
  setRelayState?: boolean;
  scheduleOverride?: AccessoryOverrideCommand;
  evCharger?: {
    zappi?: ZappiCommand;
  };
  ocpp?: OCPPCommand;
};

export type AccessoryCommandResponse = {
  success: boolean;
  status?: string;
  message?: string;
  data?: ToggleCommandResponse;
};

export type PlantAccessory = {
  // required keys
  id: AccessoryId;
  plantId: PhysicalPlantId;
  type: AccessoryType;
  provider: string;
  providerDeviceId: string;
  providerDeviceName: string;
  timezone: string;
  scheduleState: boolean | null,
  tradingOverrideState: boolean | null;
  overrideState: boolean | null;
  // optional keys
  providerDeviceChildId?: string;
  displayName?: string;
  connectedAppliance?: string;
  installDate?: number;
  host?:string;
  online?:boolean;
  onlineStatusSince?:number;
  consentActive?:boolean;
  ocppTransactionId?:number;
  ocppUserChargingMode?: OCPPChargeMode;
};

export interface ScheduledCommand {
  chargeLimit: number;
}

export interface OCPPSchedule {
  accessoryId: number;
  id: number;
  created: number;
  day: string;
  start: HHMM;
  end: HHMM;
  instruction: Instruction;
  scheduledCommand: ScheduledCommand;
  admin: true;
  enabled: true;
  expiryDate?: number;
  lastUpdated: number;
}

export enum ScheduleDays {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
  Daily = "daily",
}

export interface AccessorySchedulePostBody {
  days: ScheduleDays[];
  start: HHMM;
  end: HHMM;
  instruction: Instruction;
  scheduledCommand: ScheduledCommand;
  enabled: boolean;
  expiryDate?: number;
}

export interface AccessoryScheduleGroup {
  start: HHMM;
  end: HHMM;
  chargeLimit: Watts;
  days: Array<ScheduleDays>;
  schedules: Array<OCPPSchedule>;
  isActive: boolean;
}
