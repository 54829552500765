/** The trading log screen for a plant device */
import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./energy.module.scss";
import {
  ArrowLeft,
  ArrowRight,
  Download,
  ExpandMore,
  FileDownloadOff,
  Filter,
  KeyboardBackspace,
  Refresh
} from "@mui/icons-material";
import LayoverContainer from "components/LayoverContainer/LayoverContainer";
import {
  DropdownChecklist,
  Option
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { TIME_RANGES } from "apps-middleware/util/time";
import { IDateRange } from "apps-middleware/types/time";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import LuxonAdapter from "@date-io/luxon";
import { DateTime } from "luxon";
import { useAppDispatch } from "apps-middleware/redux/store/hooks";
import Loading from "components/Loading/Loading";
import { getTradingHistoryThunk } from "apps-middleware/redux/asyncThunks";
import { ServerResponse } from "apps-middleware/types/server";
import { isDeyeParamList, OrderDirection, TradingHistory } from "apps-middleware/types/trading";
import { tradingOrderToSemanticName } from "apps-middleware/constants/trading";
import exportFromJSON from "export-from-json";

type PlantObject = {
  id: string;
  name: string;
  plantName: string;
  plantProviderName: string;
}

type DownloadDatum = {
  "Timestamp": number;
  "Order Direction": OrderDirection;
  "Status": string;
  "Trading Algorithm Payload": {
    orderDirection: OrderDirection,
    orders: object[],
    reason?: string,
  };
  "Inverter Commands Response": {
    taskId?: number,
    commands?: object[],
  };
}

const directions: Array<OrderDirection> = [
  "None", "MaximiseImports", "MaximiseImportsBanExports", "MaximiseExports",
  "MaximiseExportsBatteryPassive", "SelfConsumption", "SelfConsumptionBanExports",
  "NoData"
];

const directionFilterOptions: Array<Option> = directions.map((f) => ({
  name: tradingOrderToSemanticName[f] as string,
  value: f as OrderDirection
}));

const DEFAULT_DIRECTION_FILTER_STATE: Array<OrderDirection> = directions;

let isMounted = false;

/**
 * TODO:
 */
export default function TradingLogsPage() : JSX.Element {
  const { state } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isPlantObject(object: any): object is PlantObject {
    return ( object &&
      "id" in object &&
      "name" in object &&
      "plantName" in object &&
      "plantProviderName" in object
    );
  }

  // Validate the plant state
  if (isPlantObject(state)) {
    return <PageBody plant={state} />;
  } else {
    return (
      <div className={styles.container}>
        <LayoverContainer>
          <>
            <Typography variant="h1">404</Typography>
            <div style={{ height: 10 }}></div>
            <Typography>Could not find a Plant Object. You must navigate to this page from a
              monitoring page, you cannot access this page directly via a URL.
            </Typography>
            <div style={{ height: 10 }}></div>
            <Typography>If you got to this page from a device's energy monitoring page, please
              contact an administrator.
            </Typography>
          </>
        </LayoverContainer>
      </div>
    );
  }
}

function PageBody({ plant }: {plant: PlantObject}): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [directionFilter, setDirectionFilter] =
    useState<Array<OrderDirection>>(DEFAULT_DIRECTION_FILTER_STATE);
  const [dateBounds, setDateBounds] = useState<IDateRange>(
    TIME_RANGES.TODAY.range
  );
  const [rawLogs, setRawLogs] = useState<TradingHistory[]>([]);
  const [logs, setLogs] = useState<TradingHistory[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [expanded, setExpanded] = useState<number | false>(false);

  function goBack() {
    navigate(-1);
  }

  function resetFilter() {
    setDirectionFilter(DEFAULT_DIRECTION_FILTER_STATE);
    setDateBounds(TIME_RANGES.TODAY.range);
    filterLogs(DEFAULT_DIRECTION_FILTER_STATE);
  }

  function filterLogs(filter: OrderDirection[]) {
    setLogs(rawLogs.filter((log) => {
      return filter.includes(log.trade.orderDirection);
    }));
  }

  function decrementPageValue() {
    setPageNumber(Math.max(pageNumber - 1, 1));
  }

  function incrementPageValue() {
    setPageNumber(pageNumber + 1);
  }

  function getStatusFromTradingHistory(log: TradingHistory) {
    let status = log.completedSuccessfully ? "Successful" : "Failure";
    if (!log.completedSuccessfully && log.success) {
      const paramList = log.success.success[0].param_list;
      if (!isDeyeParamList(paramList)) {
        paramList.forEach((command) => {
          if (command.command_status === 4) {
            status = "Partial Failure";
          }
        });
      }
    }
    return status;
  }

  function downloadLogs() {
    const data: DownloadDatum[] = [];
    logs.forEach((log) => {
      data.push({
        "Timestamp": log.timestamp,
        "Order Direction": log.trade.orderDirection,
        "Status": getStatusFromTradingHistory(log),
        "Trading Algorithm Payload": {
          orderDirection: log.trade.orderDirection,
          orders: log.trade.orders,
          reason: log.success?.success[0].task_name.split("Reason:")[1].split(" - ")[0],
        },
        "Inverter Commands Response": {
          taskId: log.success?.success[0].task_id,
          commands: log.success?.success[0].param_list,
        }
      });
    });

    const startDate = dateBounds.startDateTime.toFormat("yyyy-MM-dd");
    const endDate = dateBounds.endDateTime.toFormat("yyyy-MM-dd");
    const fileName = `${plant.plantName}_${startDate}_${endDate}`;
    const exportType = "csv";

    exportFromJSON({data, fileName, exportType});
  }

  function commandValueToString(param_code: string, value: string) {
    switch(param_code) {
      case "10001":
      case "10002":
      case "10008":
      case "10010":
      case "10014":
        return Number.parseInt(value) / 10;
      case "10003":
        switch(value) {
          case "2":
            return "Compulsory Mode";
          case "3":
            return "External Dispatch Mode";
          case "4":
            return "VPP Mode";
          case "0":
          default:
            return "Spontaneous Self-Use Mode";
        }
      case "10004":
        switch(value) {
          case "170":
            return "Charge";
          case "187":
            return "Discharge";
          case "204":
          default:
            return "Stop";
        }
      case "10006":
      case "10007":
      case "10012":
        switch(value) {
          case "170":
            return "Enable";
          case "85":
            return "Prohibition";
          default:
            return `Unknown Enable/Disable Order: ${value}`;
        }
      case "10009":
        switch(value) {
          case "85":
            return "OFF";
          case "161":
            return "Power Factory (PF)";
          case "162":
            return "Reactive power ramp rate setting Q(t)";
          case "163":
            return "Q(P) curve setting";
          case "164":
            return "Q(U) curve setting";
          default:
            return `Unknown Retrofitting Order: ${value}`;
        }
      case "10011":
        switch(value) {
          case "174":
            return "Reboot";
          case "206":
            return "Shutdown";
          case "207":
            return "Boot";
          default:
            return `Unknown Boot/Shutdown Order: ${value}`;
        }
      default:
        return value;
    }
  }

  const handleAccordion = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thunk = useRef<any>();

  const fetchLogs = async () => {
    setIsFetching(true);
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(getTradingHistoryThunk({
      plantId: plant.id,
      endDateTime: dateBounds.endDateTime,
      startDateTime: dateBounds.startDateTime
    }));

    thunk.current?.unwrap().then((
      response: ServerResponse["v3"]["plants"]["{plantId}"]["trading"]["history"]["200"]
    ) => {
      if (!isMounted) return;
      setRawLogs(response[plant.id]);
      setLogs(response[plant.id]);
    })
      .catch(() => {
        // abort silently
      })
      .finally(() => {
        if (!isMounted) return;
        setIsFetching(false);
        thunk.current = undefined;
      });

    setDirectionFilter(DEFAULT_DIRECTION_FILTER_STATE);
    setPageNumber(1);
    setExpanded(false);
  };

  useEffect(function onload() {
    isMounted = true;
    return function onUnload() {
      isMounted = false;
    };
  }, []);

  // Useful Constants
  const filtersAreInDefaultState = (
    JSON.stringify(directionFilter) === JSON.stringify(DEFAULT_DIRECTION_FILTER_STATE) &&
    dateBounds.startDateTime === TIME_RANGES.TODAY.range.startDateTime &&
    dateBounds.endDateTime === TIME_RANGES.TODAY.range.endDateTime
  );

  const commandStatusRecord: Record<number, string> = {
    1: "Waiting",
    2: "In Progress",
    4: "Successful",
    5: "Failed",
    6: "Timeout",
  };
  const commandStatusColour: Record<number, string> = {
    1: "beige",
    2: "blue",
    4: "green",
    5: "red",
    6: "orange",
  };
  const successStatusColour: Record<string, string> = {
    "Successful": "green",
    "Failure": "red",
    "Partial Failure": "orange",
  };

  const paramCodeRecord: Record<string, string> = {
    10001: "SOC Upper Limit",
    10002: "SOC Lower Limit",
    10003: "Energy Management Mode",
    10004: "Charge/Discharge Order",
    10005: "Charge/Discharge Power",
    10006: "Retrofitting Enable",
    10007: "Export Power Limit Switch",
    10008: "Export Power Limit",
    10009: "Reactive Power Switch",
    10010: "Reactive Power Setting",
    10011: "Boot/Shutdown",
    10012: "Feed-in Limitation Enable",
    10013: "Feed-in Limitation Value",
    10014: "Feed-in Limitation Ratio",
    10015: "Forced Charging Target 1",
    10016: "Forced Charging Target 2",
    10017: "External EMS Heartbeat",
  };

  // Set the logs content
  let logContent: JSX.Element[] = [
    <div key="table-header" style={{
      display: "grid", gridTemplateColumns: "repeat(3, minmax(24ch, 32ch))", padding: "16px"
    }}>
      <Typography> Date Time </Typography>
      <Typography> Order Direction </Typography>
      <Typography> Status </Typography>
    </div>
  ];
  if (logs.length > 0) {
    logs.forEach((log, index) => {
      // Hacky Pagination Logic
      const maxIndex = pageNumber * 100;
      const minIndex = maxIndex - 100;
      if (index <= minIndex || index > maxIndex) {
        return;
      }

      const successful = getStatusFromTradingHistory(log);
      const successfulColour = successStatusColour[successful];

      const orders: JSX.Element[] = [];
      log.trade.orders.forEach((order, index) => {
        orders.push(
          <div key={`tradeOrder-${index}`} style={{marginLeft: "2em"}}>
            <Typography><b>Parameter Type:</b> {order.parameterType}</Typography>
            <Typography marginBottom="0.25em">
              <b>Value:</b> {order.value.toString()}</Typography>
          </div>
        );
      });

      const commands: JSX.Element[] = [
        <Typography key={`taskId-${index}`}sx={{marginBottom: "0.5em" }}>
          Task ID: {log.success?.success[0].task_id}
        </Typography>
      ];
      const paramList = log.success?.success[0].param_list;
      if (isDeyeParamList(paramList)) {
        paramList.forEach((command, i) => {
          const touSettings = command.timeUseSettingItems?.at(0);
          commands.push(
            <div key={`inverterCommand-${i}`}>
              <Typography><b>gridChargeAction:</b> {command.gridChargeAction}</Typography>
              <Typography><b>solarSellAction:</b> {command.solarSellAction}</Typography>
              <Typography><b>maxSellPower:</b> {command.maxSellPower}</Typography>
              <Typography><b>maxSolarPower:</b> {command.maxSolarPower}</Typography>
              <Typography><b>workMode:</b> {command.workMode}</Typography>
              <Typography><b>zeroExportPower:</b> {command.zeroExportPower}</Typography>
              <br/>
              <Typography><b>touAction:</b> {command.touAction}</Typography>
              { command.touAction
                ? (
                  <>
                    <Typography>&#x7B;</Typography>
                    <div style={{marginLeft: "2em"}}>
                      <Typography>
                        <b>enableGeneration:</b> {touSettings?.enableGeneration.toString()}
                      </Typography>
                      <Typography>
                        <b>enableGridCharge:</b> {touSettings?.enableGridCharge.toString()}
                      </Typography>
                      <Typography><b>power:</b> {touSettings?.power}</Typography>
                      <Typography><b>soc:</b> {touSettings?.soc}</Typography>
                      <Typography><b>voltage:</b> {touSettings?.voltage}</Typography>
                    </div>
                    <Typography>&#x7D;</Typography>
                  </>
                )
                : ""
              }
            </div>
          );
        });
      } else if (paramList !== undefined) {
        paramList.forEach((command, index) => {
          const statusColour = commandStatusColour[command.command_status];
          commands.push(
            <div key={`inverterCommand-${index}`}>
              <Typography>&#x7B;</Typography>
              <div style={{marginLeft: "2em"}}>
                <Typography>Status: <span style={{color: statusColour}}>
                  {commandStatusRecord[command.command_status]}
                </span></Typography>
                <Typography>Created at: {command.create_time}</Typography>
                <Typography>Parameter: {paramCodeRecord[command.param_code]}</Typography>
                <Typography>Value: {commandValueToString(command.param_code, command.set_value)}
                </Typography>
                {command.unit ? <Typography>Units: {command.unit}</Typography> : null}
              </div>
              <Typography>&#x7D;</Typography>
            </div>
          );
          //
        });
      }

      let reason = log.trade.reason;
      if (!reason) {
        reason = "None";
      } else {
        reason = reason.split(" - ")[0];
      }
      logContent.push(
        <Accordion
          expanded={expanded === index}
          onChange={handleAccordion(index)}
          key={`log-${index}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls={`log${index}bh-content`}
            id={`log${index}bh-header`}
          >
            <div className={styles.logSummary}>
              <Typography>
                {DateTime
                  .fromMillis(log.timestamp)
                  .toLocal()
                  .toFormat("yyyy/MM/dd t")}
              </Typography>
              <Typography sx={{ color: "text.secondary"}}>
                {log.trade.orderDirection}</Typography>
              <Typography sx={{ color: successfulColour }}>
                {successful}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.logDetails}>
              <div>
                <Typography sx={{fontWeight: "bold"}}>Trading Algorithm</Typography>
                <Typography sx={{marginBottom: "0.5em" }}>OrderDirection: {log.trade.orderDirection}
                </Typography>
                <Typography>&#x7B;</Typography>
                {orders}
                <Typography>&#x7D;</Typography>
                <Typography sx={{marginTop: "0.5em" }}>
                  Reason: {reason || "None"}
                </Typography>
              </div>
              <div>
                <Typography sx={{fontWeight: "bold"}}>Inverter Commands</Typography>
                {commands}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      );
      // two columns. Trading Response. Sungrow Response.
    });
  } else {
    if (!isFetching) {
      logContent = [<p key="log-noLogs" style={{marginLeft: "1em"}}>
        Press <code style={{fontSize: "0.8125rem"}}>REFRESH</code> to fetch new trading logs. You
        will need to refresh whenever you change the date to get new logs.
      </p>];
    }
  }

  return (
    <div className={styles.container} style={{
      width: "100%",
      height: "100%",
      display: "grid",
      gridTemplateRows: "auto 1fr"
    }}>
      {/* header */}
      <div style={{
        display: "flex",
        gap: "1em",
        alignItems: "center",
        marginBottom: "1em"
      }}>
        <Button
          onClick={goBack}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<KeyboardBackspace />}>
          Back
        </Button>
        <DropdownChecklist
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.5,
          }}
          defaultSelected={DEFAULT_DIRECTION_FILTER_STATE}
          controlledSelected={directionFilter.map((f) => ({ "name": f, "value": f }))}
          items={directionFilterOptions}
          onSelectionChange={(items) => {
            const newFilter = items.map((o) => o.value as OrderDirection);
            setDirectionFilter(newFilter);
            filterLogs(newFilter);
          }}
          labelText={"Filter Direction"}
          icon={<Filter />} />
        <LocalizationProvider dateAdapter={LuxonAdapter}>
          <DatePicker
            label="Start Date"
            value={dateBounds.startDateTime}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy HH:mm"
            onChange={(newVal) => {
              if (!newVal) return;
              setDateBounds({
                ...dateBounds,
                startDateTime: newVal.startOf("day")
              });
            }}
            maxDate={dateBounds.endDateTime}
          />
          <DatePicker
            label="End Date"
            value={dateBounds.endDateTime}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy HH:mm"
            onChange={(newVal) => {
              if (!newVal) return;
              setDateBounds({
                ...dateBounds,
                endDateTime: newVal.endOf("day")
              });
            }}
            minDate={dateBounds.startDateTime}
            maxDate={DateTime.now()}
          />
        </LocalizationProvider>
        <div style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Button
            variant="outlined"
            disabled={filtersAreInDefaultState}
            startIcon={<Refresh />}
            onClick={resetFilter}>
            Reset Filters
          </Button>
        </div>
      </div>
      <div style={{
        marginBottom: "2em"
      }}>
        <Typography variant="h4">{plant.name} Trading Logs</Typography>
      </div>

      {/* Log View */}
      <div className={styles.box}>
        <div className={styles.logBar}>
          <Button
            onClick={fetchLogs}
            variant="outlined"
            color="primary"
            size="small"
            className={styles.refreshButton}
          >
            <Refresh/> &nbsp; Refresh
          </Button>
          <div className={styles.pageSelector}>
            {
              logs.length > 0
                ? <Button
                  onClick={downloadLogs}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={styles.refreshButton}
                >
                  <Download/> &nbsp; Download
                </Button>
                : <Button
                  disabled
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={styles.refreshButton}
                >
                  <FileDownloadOff/> &nbsp; Download
                </Button>
            }
            <Button size="small" onClick={decrementPageValue} className={styles.arrowButton}>
              <ArrowLeft/>
            </Button>
            <span>Page {pageNumber}</span>
            <Button size="small" onClick={incrementPageValue} className={styles.arrowButton}>
              <ArrowRight/>
            </Button>
          </div>
        </div>
        <div>
          {
            isFetching && <LayoverContainer>
              <Loading informationText={"Fetching Red Pi Data..."} />
            </LayoverContainer>
          }
          {
            <div className={styles.logBox}>
              {logContent}
            </div>
          }
          <div className={styles.pageSelector}>
            <Button size="small" onClick={decrementPageValue} className={styles.arrowButton}>
              <ArrowLeft/>
            </Button>
            <span>Page {pageNumber}</span>
            <Button size="small" onClick={incrementPageValue} className={styles.arrowButton}>
              <ArrowRight/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

