import { PurchaseInfo } from "../types/products";
import {
  AccessoryCommandResponse,
  OCPPSchedule,
  PlantAccessory,
  ServerAccessoryData,
} from "./accessory";
import { INotification } from "./notification";
import {
  AlertType,
  EnvironmentalData,
  FinancialData,
  IMinifiedPlant,
  IPlant,
  IPlantData,
  IPossiblePlant,
  PlantId,
  ConfigurationType,
  TariffType,
  WarrantyGetResponse,
} from "./plant";
import { PowerIntervalType, PriceIntervalType, UnixTimeStamp } from "./redux";
import {
  PlantTradingStatus,
  TradingHistory,
  UserOverrideCommandSchema,
} from "./trading";
import { SoracomAirStats, SoracomSim } from "./sim";
import { SchedulePlantCommandEvent } from "./schedules";
import { UserAccessLevel } from "./user";

export interface ICustomResponse<ResponseType, Status = number> {
  ok: boolean; //is the response ok or not.
  status: Status; //status code of response
  statusText: string; //status text from server
  data: ResponseType; //the response type returned from a call
}

export type RequestResult<T> = Promise<ICustomResponse<T> | null>;
export type RequestResultAsError = ICustomResponse<ErrorBody>;
export type RequestResultPerCode<T, U> = Promise<ICustomResponse<T, U> | null>;

export type RequestMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "HEAD"
  | "DELETE"
  | "PATCH"
  | "OPTIONS"
  | "CONNECT"
  | "TRACE";

export type ErrorBody = {
  message: string;
  errors?: Array<{
    path?: string;
    message?: string;
  }>;
};

export interface IPlantGetDataV2Params {
  plantIds: string[];
  startUnixTimestamp: number;
  endUnixTimestamp: number;
  minuteInterval: PowerIntervalType;
}

export interface IPriceDataV2 {
  unixTimestamp: number;
  importPrice: number;
  exportPrice: number;
  spotPrice: number;
  renewableMix: number;
  resolution: PriceIntervalType;
}
export type IPlantGetPricesV2Response = Array<IPriceDataV2>;

export interface IPlantGetPricesV2Params {
  plantId: PlantId;
  startUnixTimestamp: UnixTimeStamp;
  endUnixTimestamp: UnixTimeStamp;
  resolution: PriceIntervalType;
}

export type IWeatherResponse = {
  icon?: string;
  temp?: number;
};

export type IPlantGetDataV2Response = {
  data: Record<PlantId, IPlantData[]>;
};

export interface ICumultiveDailyDataParams {
  plantIds?: Array<PlantId>;
  startUnixTimestamp: UnixTimeStamp;
  endUnixTimeStamp: UnixTimeStamp;
}
export interface ICumulativeDailyFinancialDataResponse {
  financialData: FinancialData[];
}

export interface ICumulativeEnvironmentalDataResponse {
  environmentalData: EnvironmentalData[];
}

export interface IListUserPlantsResponse {
  pagination: IPaginationResponse;
  plants: IPlant[];
  possiblePlants: IPossiblePlant[];
  demoPlants: IPlant[];
}

export type V3PlantsListQueryparams = {
  address?: string,
  anyFieldQuery?: string,
  appSearchQuery?:string, // A multi-field query matching the requirements of the EMU app
  batterySerial?: string,
  chassisId?: number,
  configurationType?: ConfigurationType,
  demo?: boolean,
  displayName?: string, // The views display name
  limit?: number,
  minified?: boolean,
  name?: string,
  page?: number,
  plantId?: string,
  postcode?: string,
  salesOrderNo?: string,
  serialNumber?: string,
  showPossible?: boolean,
  status?: string,
  timezone?: string,
  isVip?: boolean,
  tradingAllowed?: boolean,
  tradingEnabled?: boolean,
  cryptoAllowed?: boolean,
  isOptimus?: boolean,
  hasSmartEvCharger?: boolean,
  hasLoadControl?: boolean,
  hasPowerRanger?: boolean,
  viewName?: string, // the view name
}

export interface IMinifiedPlantResponse {
  plants: Array<IMinifiedPlant>;
}

export interface IPagination {
  currentPage: number;
  numberPerPage: number;
}

export interface IPaginationResponse extends IPagination {
  totalAvailable: number;
}

export interface IPlantSearchParams {
  plantId?: string;
  name?: string;
  timezone?: string;
  address?: unknown;
  postcode?: unknown;
  configurationType?: ConfigurationType;
  salesOrderNo?: unknown;
  status?: string;
  serialNumber?: unknown;
}

export type IUpdatePlantResponse = null;

export interface IUpdatePlantOptions {
  name?: string;
  providerCode?: number;
  providerPlantId?: string;
  providerDataAccessPlantId?: string;
  maxExportWatts?: number;
  importPriceSetPoint?: number;
  exportPriceSetPoint?: number;
  tradingEnabled?: boolean;
  tradingAllowed?: boolean;
  amberApiKey?: string | null;
  amberSiteId?: string | null;
  hiveonIdentifier?: string | null;
  cryptoAllowed?: boolean;
  cryptoEnabled?: boolean;
  evChargingAllowed?: boolean;
  evChargingEnabled?: boolean;
}

export interface IConnectCustomerToPlantResponse {
  success: boolean;
  message: string;
}

export interface IRegisterUserResponse {
  success: boolean;
  message: string;
}

export interface IFetchAlertsQueryParams {
  pagination: IPagination;
  startUnixTimestamp: number;
  endUnixTimestamp?: number;
  seen?: boolean;
  nameQuery?: string;
}
export type IFetchAlertsResponse = {
  plantIds: PlantId[];
  pagination: IPaginationResponse;
};

export interface IIgnoreAlertQueryParams {
  plantDataSourceIds: number[];
  alertTypes: AlertType[];
  expiryUnixTimestamp?: number;
  ignore: boolean;
}

export interface IMarkAlertAsSeenQueryParams {
  alerts: {
    alertId: string;
    seen: boolean;
  }[];
  asUser?: boolean;
}

export interface ISubmitTicketParams {
  category: string;
  description: string;
  message: string;
}

export interface ISubmitTicketResponse {
  message: string;
}

export type IAggregatePlantResponse = {
  points: IPlantData[];
};

export interface IAggregatePlantRequestParams {
  plantIds?: PlantId[];
  startUnixTimestamp: UnixTimeStamp;
  endUnixTimestamp: UnixTimeStamp;
  minuteInterval: number;
}

export interface IPasswordRecoveryRequestParams {
  email: string;
}

export interface IRemoveUserRequestParams {
  feedback?: string;
}

export type IFetchNotificationsResponse = INotification[];

export interface ILinkByQRParams {
  qrId: string;
}
export interface ILogoutParams {
  token?: string;
}

export interface IUserPersonalDetails {
  name?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  accessLevel?: UserAccessLevel;
  skunkworksAccess?: boolean;
}

export interface IUpdateChallengeParams {
  challengeId: string;
  joinedOn?: number | boolean | null;
  hidden?: boolean;
  seen?: boolean;
}

//TODO: move these things to a better spot
type Year = string; //2022
type Month = string; //2023-12
type Day = string; //2023-12-31

export enum CachedDataIntervals {
  Yearly = "yearly",
  Monthly = "monthly",
  Daily = "daily",
}

export type DailyIntervalResponse<T> = {
  [CachedDataIntervals.Daily]: Record<Day, T>;
};

export type MonthlyIntervalResponse<T> = {
  [CachedDataIntervals.Monthly]: Record<Month, T>;
};

export type YearlyIntervalResponse<T> = {
  [CachedDataIntervals.Yearly]: Record<Year, T>;
};

type CachedIntervalResponse<T> = {
  interval: CachedDataIntervals;
  timezone: string;
  data: Record<string, T>;
};

//TODO: move over to this kind of format for server responses
//instead of just a new interface for every call. This below method
//makes it easier to track.
export interface ServerResponse {
  v3: {
    plants: {
      "{plantId}": {
        data: {
          financial: {
            "{interval}": {
              GET: {
                "200": CachedIntervalResponse<FinancialData>;
              };
            };
          };
          environmental: {
            "{interval}": {
              GET: {
                "200": CachedIntervalResponse<EnvironmentalData>;
              };
            };
          };
        };
        trading: {
          history: {
            "200": Record<PlantId, TradingHistory[]>;
          };
          status: {
            "200": PlantTradingStatus;
          };
        };
        accessories: {
          GET: {
            "200": Array<PlantAccessory>;
          };
          "{accessoryId}": {
            GET: {
              "200": PlantAccessory;
            };
            PUT: {
              "200": PlantAccessory;
            };
            DELETE: {
              "204": undefined;
            };
            status: {
              GET: {
                "200": ServerAccessoryData;
              };
            };
            command: {
              POST: {
                "200": AccessoryCommandResponse;
              };
            };
            schedules: {
              GET: {
                "200": Array<OCPPSchedule>;
              };
              POST: {
                "200": undefined;
              };
              "{scheduleId}": {
                DELETE: undefined;
              }
            };
          };
        };
        tariff: {
          GET: {
            "200": {
              tariffType: TariffType;
              tariffName: string;
              peak?: number;
              shoulder?: number;
              offPeak?: number;
              single?: number;
              export?: number;
            };
          };
          PUT: {
            "200": {
              tradingAllowed: boolean;
              tradingEnabled: boolean;
              amberSetSuccessfully: boolean;
            };
          };
        };
        PUT: {
          "204": void;
        };
        "user-commands": {
          "{instruction}": {
            PUT: {
              "200": unknown;
            };
          };
          GET: {
            "200": Array<UserOverrideCommandSchema>;
          };
          DELETE: {
            "204": undefined;
          };
        };
        schedules: {
          GET: {
            "200": SchedulePlantCommandEvent[];
          };
        };
      };
    };
    purchases: {
      "{appStore}": {
        GET: {
          "200": Array<PurchaseInfo>;
        };
        PUT: {
          "201": void;
        };
      };
    };
    warranties: {
      "{qrId}": {
        PUT: {
          "204": void;
        };
        GET: {
          "200": WarrantyGetResponse;
          "204": void;
        };
      };
    };
    sims: {
      "{simId}": {
        GET: {
          "200": SoracomSim;
        };
        data: {
          GET: {
            "200": SoracomAirStats[];
          };
        };
        command: {
          POST: {
            "200": SoracomSim;
          };
        };
      };
    };
    schedules: {
      "{scheduleId}": {
        "GET": {
          "200": SchedulePlantCommandEvent
        }
        "PUT": {
          "200": SchedulePlantCommandEvent
        },
        "DELETE": {
          "204": undefined,
        }
      },
      "POST": {
        "200": SchedulePlantCommandEvent,
        "409": ErrorBody
      }
    },
    "views": {
      "{viewId}": {
        "PUT": {
          "200": {
            name:string,
            displayName:string,
          }
        }
      },
    },
  }
}

export type GetRedPiDevicesQueryParams = {
  timezone?: string;
  postcode?: string;
  configurationType?: string;
  isVip?: boolean;
  isOptimus?: boolean;
  cryptoEnabled?: boolean;
  tradingEnabled?: boolean;
  isRegistered?: boolean;
  isHealthy?: boolean;
};

export type GetRedPiLogsQueryParams = {
  ggid?: string;
  operation?: string;
  page?: number;
  pageLimit?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PostRedPiCommandBody = any;
