import { isPlantInfo } from "../util/type";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import {
  HouseMode,
  IMinifiedPlant,
  IPlant
} from "../types/plant";
import {
  fetchPowerDataThunkAction,
  fetchPriceDataThunkAction,
  fetchSchedulesThunk,
  getTradingHistoryThunk,
  getTradingStatusThunk,
  updatePlantThunk
} from "../redux/asyncThunks";
import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  plantDataToUIFriendlyPowerData,
  priceDataToUIFriendlyPriceData
} from "../redux/utils";
import { IPriceDataV2 } from "../types/server";
import { findCurrentPriceDataFromArray } from "../redux/selectors/priceData";
import { RootState } from "../redux/store/configureStore";
import { putPlant, putView } from "../api/plant";
import { getHighestAlertPriority, getIsGridConected, getRenderedName } from "../util/plant";
import { LabelledTimeRange, makeLabelledTimeRange } from "../util/time";
import { OrderDirection, TradeHistoryFidelity, TradingChoices } from "../types/trading";
import { simpleStringifyCompare } from "../util/general";
import useIsMounted from "./useIsMounted";
import { SchedulePlantCommandEvent } from "../types/schedules";
import {
  getTodayAsDayProp,
  isScheduleRecurring,
  isScheduleExpired,
  scheduleTimeStringToDateTime,
  scheduleTimeToSortableNumber } from "../util/schedules";

/**
 *
 * usePlant is the primary method of interacting with plant related data.
 * It is a one stop shop for most data we store related to a specific
 * viewId/plantId. The reasoning behind usePlant is we have a bunch of different
 * endpoints interfacing with server data based on the plantId as the universal
 * key to accessing this data. usePlant is the way we get all that data into
 * one location with some useful helper functions.
 *
 * Good example: tradingStatus is its own endpoint, we get his based on passing a
 * plantId to the server. It isn't related to the plantInfo at all. If we need
 * both plantInfo and tradingStatus at once usePlant does this as it has the
 * selectors required to get the data into the redux based on the provided plantId.
 *
 * IN ITS CURRENT STATE: usePlant will be our biggest render liability. I've done
 * some work to try and reduce how heavy it is but we can only do so much. in situations
 * where you only need a single prop out of plantInfo, do not use usePlant. Use a specicic
 * selector for that property. I think I've also over loaded usePlant a bit in general
 * so I'm slowly trying to disect things out where it makes sense. E.g. I've removed
 * the financial and environmental data from usePlant and put it in its own hook called
 * useFinancialData & useEnvironmentalData (see useCachedData.ts). The kind of
 * logic going into those hooks was making usePlant quite large when it was here so I
 * moved it out and put it in its own hook. There are some situations where we can continue
 * doing this for other usePlant things.
 *
 * The goal is usePlant will basically be as minimal impacting on renders as possible.
 * If we need to this means basically extracing out plantInfo prop by prop or making the
 * comparission functions for useAppSelector water tight so we're not copping so many renders.
 * The current issue is how frequently the currentData prop will change (which makes sense
 * but wish it wouldn't.)
 *
 * @param options pass options to this hook
 * @returns useful plant data
 */
export const usePlant = (
  {
    plantId,
    getFullInfoASAP = true,
    defaultTradeHistoryFidelity = TradeHistoryFidelity.ConsequentialFails
  }: {
    plantId?: string,
    getFullInfoASAP?: boolean,
    defaultTradeHistoryFidelity?: TradeHistoryFidelity,
  }
) => {
  const isMounted = useIsMounted();
  const [powerDataLoading, setPowerDataLoading] = useState(false);
  const [plantInfoLoading, setPlantInfoLoading] = useState(false);
  const [priceDataLoading, setPriceDataLoading] = useState(false);
  const [tradingHistoryLoading, setTradingHistoryLoading] = useState(false);
  const [plantPutLoading, setPlantPutLoading] = useState(false);
  const [viewPutLoading, setViewPutLoading] = useState(false);
  const [tradeHistoryFidelity, setTradeHistoryFidelity] = useState(defaultTradeHistoryFidelity);
  const [schedulesLoading, setSchedulesLoading] = useState(false);

  const dispatch = useAppDispatch();
  //todo: create a cached selector for this if rendering is too costly
  const plant: IPlant | IMinifiedPlant | undefined = useAppSelector(
    (state: RootState) => {

      const targetId = plantId ? plantId : state.appData.currentPlantId;
      if (targetId === undefined) return undefined;

      const currentPlant = state.appData.plants.data[targetId];
      const demoPlant = state.appData.demoPlants.data[targetId];

      if (currentPlant) return currentPlant;
      else if (demoPlant) return demoPlant;
      else return undefined;
    },
    (oldPlant, newPlant) =>
      JSON.stringify(oldPlant) === JSON.stringify(newPlant)
  );
  const haveFullInfo = isPlantInfo(plant);
  const isDemo = haveFullInfo ? plant.permissionLevel === "DEMO" : undefined;
  const isOffGrid = haveFullInfo ? !getIsGridConected(plant) : false;
  const targetPlantId = plant?.plantId;
  const targetPhysicalPlantId = plant?.physicalPlantId;

  const tradingStatus = useAppSelector(
    (state: RootState) => {
      if (plant?.plantId === undefined) return;
      return state.appData.tradingStatus.data[plant?.plantId]?.plantTradingStatus;
    },
    simpleStringifyCompare
  );

  const refreshPlantTradingStatus = useCallback(
    () => {
      if (plant?.plantId) dispatch(getTradingStatusThunk(plant.plantId));
    },
    [plant?.plantId]
  );

  const currentPriceData: IPriceDataV2 | undefined = useAppSelector(
    (state: RootState) => {
      if (plant === undefined) return undefined;
      const thisPlantPriceData = state.appData.priceData.data[plant.plantId];
      if (!thisPlantPriceData) return undefined;
      const current = findCurrentPriceDataFromArray(Object.values(thisPlantPriceData[5]));
      return current ? current : undefined;
    },
    simpleStringifyCompare
  );

  const tradingChoices: TradingChoices = useAppSelector(
    (state: RootState) => {
      const tradeSuccessful: LabelledTimeRange[] = [];
      const tradingCommandFails: DateTime[] = [];
      const stopTradingCommandFails: DateTime[] = [];
      let lastSuccessfulCommand: OrderDirection | undefined;

      const result: TradingChoices = {
        successfulTrades: tradeSuccessful,
        tradingCommandFails,
        stopTradingCommandFails
      };
      if (!plant) return result;

      const fullHistory = state.appData.tradingHistory.data[plant.plantId];
      if (!fullHistory) return result;

      const entries = Object.values(fullHistory);
      if (entries.length === 0) return result;
      let successfulRange: DateTime | undefined = undefined;

      for (let i = 0; i < entries.length; i++) {
        const history = entries[i];
        const isCompulsary = history.trade.orderDirection !== "SelfConsumption";
        const wasASuccess = history.completedSuccessfully ||
          (!history.completedSuccessfully && history.success === undefined);
        const wasAnOutRightFail = !history.completedSuccessfully && history.success !== undefined;

        const currentCommandIsDifferentToLastSuccessfulCommand =
          history.trade.orderDirection !== undefined &&
          history.trade.orderDirection !== lastSuccessfulCommand;

        if (wasASuccess) lastSuccessfulCommand = history.trade.orderDirection;

        const includeThisFailCommand = (
          wasAnOutRightFail &&
          (
            tradeHistoryFidelity === TradeHistoryFidelity.AllFails ||
            tradeHistoryFidelity === TradeHistoryFidelity.ConsequentialFails &&
            currentCommandIsDifferentToLastSuccessfulCommand
          )
        );

        const dateTime = DateTime.fromMillis(history.timestamp);

        if (
          successfulRange &&
          !isCompulsary &&
          wasAnOutRightFail &&
          includeThisFailCommand
        ) {
          stopTradingCommandFails.push(dateTime);
        }

        if (
          wasAnOutRightFail &&
          isCompulsary &&
          includeThisFailCommand
        ) {
          tradingCommandFails.push(dateTime);
        }
        if (isCompulsary && wasASuccess && !successfulRange) {
          successfulRange = dateTime;
        } else if (!isCompulsary && successfulRange && wasASuccess) {
          tradeSuccessful.push(makeLabelledTimeRange(successfulRange, dateTime));
          successfulRange = undefined;
        } else if (i === entries.length - 1 && successfulRange) {
          tradeSuccessful.push(makeLabelledTimeRange(
            successfulRange,
            DateTime.now()
          ));
        }
      }

      return result;
    },
    simpleStringifyCompare
  );

  const schedules: SchedulePlantCommandEvent[] | undefined = useAppSelector(
    (state: RootState) => {
      if(targetPhysicalPlantId === undefined) return undefined;
      const schedulesForPlant = state.appData.schedules.data[targetPhysicalPlantId];
      if(schedulesForPlant === undefined) return undefined;
      const scheduleArray = [...schedulesForPlant];

      return scheduleArray
      // dont show any schedules that have expired before today or have been
      // executed already. The only things that should appear are current,
      // in the future or recurring
        .filter((schedule) => {

          // if its recurring allow it
          if(isScheduleRecurring(schedule)) return true;

          const now = DateTime.now();
          const startDt = scheduleTimeStringToDateTime(schedule.start);
          const endDt = scheduleTimeStringToDateTime(schedule.end);

          // if its currently active allow it
          if(now >= startDt && now <= endDt) return true;

          // if it has an expiry that is before right now, get rid of it
          if(isScheduleExpired(schedule)) {
            return false;
          }

          // else, just double check if its in the future,
          return getTodayAsDayProp() === schedule.day && endDt > now;
        })
        .sort((a, b) => {
          /**
           * atm we're only dealing with schedules that should either appear as today
           * or daily recurring so they're all going to be considered on the same
           * day. This means we only have to sort by time for now, we dont have to check
           * day. I've commented this code out but if we need it back we can grab it.
           */
          //if they're the same day/recurring amount we just want to sort on time
          // const bothRecurringDaily = a.day === "daily" && a.day === b.day;
          // const bothAreToday = a.day === getTodayAsDayProp() && a.day === b.day;
          // const oneIsRecurringTheOtherIsToday = (
          //   (a.day === "daily" && b.day === getTodayAsDayProp()) ||
          // (a.day === getTodayAsDayProp() && b.day === "daily")
          // );

          // if(bothAreToday || bothRecurringDaily || oneIsRecurringTheOtherIsToday) {
          const aTimeAsNumber = scheduleTimeToSortableNumber(a.start);
          const bTimeAsNumber = scheduleTimeToSortableNumber(b.start);
          return aTimeAsNumber - bTimeAsNumber;
          // } else {
          //   const aDayAsNumber = scheduleDayToSortableNumber(a.day);
          //   const bDayAsNumber = scheduleDayToSortableNumber(b.day);
          //   return bDayAsNumber - aDayAsNumber;
          // }
        });
    },
    simpleStringifyCompare
  );

  const refreshPlantInfo = useCallback(() => {
    if (targetPlantId === undefined) return;
    setPlantInfoLoading(true);
    return dispatch(updatePlantThunk(targetPlantId))
      .finally(() => {
        if (isMounted()) setPlantInfoLoading(false);
      });
  }, [targetPlantId]);

  const refreshPowerData = useCallback((bounds: LabelledTimeRange) => {
    if (targetPlantId === undefined) return;
    setPowerDataLoading(true);
    return dispatch(fetchPowerDataThunkAction({
      plantIds: targetPlantId,
      startTime: bounds.range.startDateTime,
      endTime: bounds.range.endDateTime,
      minuteInterval: 5
    }))
      .finally(() => {
        if (isMounted()) setPowerDataLoading(false);
      });
  }, [targetPlantId]);

  const refreshPriceData = useCallback((bounds?: LabelledTimeRange) => {
    if (targetPlantId === undefined) return;
    setPriceDataLoading(true);
    return dispatch(fetchPriceDataThunkAction({
      plantId: targetPlantId,
      startUnixTimestamp: bounds
        ? bounds.range.startDateTime.toMillis() : DateTime.now().toMillis(),
      endUnixTimestamp: bounds
        ? bounds.range.endDateTime.toMillis() : DateTime.now().toMillis(),
      resolution: 5
    }))
      .finally(() => {
        if (isMounted()) setPriceDataLoading(false);
      });
  }, [targetPlantId]);

  const refreshTradingHistory = useCallback((bounds: LabelledTimeRange) => {
    if (targetPlantId === undefined) return;
    setTradingHistoryLoading(true);
    return dispatch(
      getTradingHistoryThunk({
        "plantId": targetPlantId,
        "endDateTime": bounds.range.endDateTime,
        "startDateTime": bounds.range.startDateTime
      })
    )
      .finally(() => {
        if (isMounted()) setTradingHistoryLoading(false);
      });
  }, [targetPlantId]);

  async function getSchedulesForPlant() {
    if(plant === undefined || plant.physicalPlantId === undefined) return;
    setSchedulesLoading(true);
    const res = await dispatch(fetchSchedulesThunk({
      action:"get",
      plantId: plant.physicalPlantId
    }));
    if(isMounted())setSchedulesLoading(false);
    return res;
  }

  async function setPlantHouseMode(houseMode: HouseMode) {
    if (!plant) return;

    setPlantPutLoading(true);

    await putPlant(
      plant.plantId,
      {
        houseMode,
      }
    );
    await refreshPlantInfo();
    if (isMounted()) setPlantPutLoading(false);
  }

  async function setPlantDisplayName(nextDisplayName: string) {
    try {
      if(!plant) return;
      setViewPutLoading(true);
      const response = await putView(plant.plantId, {
        displayName: nextDisplayName
      });
      if(response?.ok === false) {
        // eslint-disable-next-line max-len
        throw Error(`Unable to change plant display name. ${(response.data as any).message ?? response.statusText}`);
      }
      await refreshPlantInfo();
    // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setViewPutLoading(false);
    }
  }

  const setPlantForceOffGridMode = useCallback(
    async (newState: boolean) => {
      if (targetPlantId === undefined) return;
      setPlantPutLoading(true);
      await putPlant(targetPlantId, { "forceGridStatus": newState });
      await refreshPlantInfo();
      if (isMounted()) setPlantPutLoading(false);
    },
    [targetPlantId]
  );

  useEffect(function onLoad() {
    if (getFullInfoASAP && !haveFullInfo) {
      refreshPlantInfo();
    }
  }, []);

  if (plant === undefined) return undefined;

  return {
    haveFullInfo,
    ...(haveFullInfo ? plant : {}),
    name: getRenderedName(plant),
    isDemo,
    isOffGrid,
    id: plant.plantId,
    plantInfo: {
      refresh: refreshPlantInfo,
      isFetching: plantInfoLoading
    },
    powerData: {
      refresh: refreshPowerData,
      isFetching: powerDataLoading,
      current: haveFullInfo
        ? {
          raw: plant.currentPlantData,
          uiFriendly: plant.currentPlantData
            ? plantDataToUIFriendlyPowerData(plant.currentPlantData) : undefined
        } : undefined
    },
    priceData: {
      refresh: refreshPriceData,
      isFetching: priceDataLoading,
      current: haveFullInfo ? {
        raw: currentPriceData,
        uiFriendly: currentPriceData
          ? priceDataToUIFriendlyPriceData(currentPriceData) : undefined
      } : undefined
    },
    schedules:{
      get: getSchedulesForPlant,
      data: schedules,
      isFetching: schedulesLoading,
    },
    tradingHistory: {
      refresh: refreshTradingHistory,
      isFetching: tradingHistoryLoading,
      tradingChoices,
      dataFidelity: tradeHistoryFidelity,
      changeFidelity: setTradeHistoryFidelity
    },
    tradingStatus: {
      refresh: refreshPlantTradingStatus,
      data: tradingStatus
    },
    highestAlertPriority: haveFullInfo
      ? getHighestAlertPriority(plant.alerts)
      : undefined,
    setHouseMode: {
      setPlantHouseMode,
      isFetching: plantPutLoading
    },
    setForceGridStatus: {
      set: setPlantForceOffGridMode,
      isFetching: plantPutLoading
    },
    setName: {
      setPlantDisplayName,
      isFetching: viewPutLoading
    }
  };
};
