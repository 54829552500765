import { UNITS } from "apps-middleware/constants/general";
import { Graphs, PotentialDataKeys } from "types/graph";

export const LINE_COLOURS = {
  PURPLE: "#e040fb", //pv
  CYAN: "#00ffff", // battery
  ORANGE: "#ff6e40", //Grid
  YELLOW: "#fff176", // load
  GENERATOR: "#aeea00",
  EVPURPLE: "#b140d9",

  BLUE: "#64b5f6", // battery

  PINK: "#ff4081", // import
  GREEN: "#00e676", // export
  LIGHT_GREEN: "#bbfc38", //generator
  TEAL: "#4db6ac",

  VOLTAGE: "#ffff8d",
  CURRENT: "#a7ffeb",
  HERTZ: "#b388ff"
};

export const DEFAULT_GRAPH_VISIBILITY_STATE: Record<Graphs, boolean> = {
  "Battery Percentage": true,
  "Energy Profile": true,
  "Wholesale Price": true,
  "Battery Voltage & Current": true,
  "Load Voltage & Current": true,
  "Generator Voltage & Current": true,
  "Generator Frequency": true,
  "PV Voltage & Current": true,
  "EV Charging Voltage & Current": true,
};

export const dataKeyToSemanticName: Record<PotentialDataKeys, string> = {
  pvPower: "PV Power",
  pvFromAC: "PV Power From AC",
  pvFromDC: "PV Power From DC",
  pvVoltage: "PV Voltage",
  pvCurrent: "PV Current",

  gridPower: "Grid Power",
  gridExport: "Grid Export",
  gridImport: "Grid Import",

  batteryPower: "Battery Power",
  batterySoc: "Battery Percentage",
  reportedBatterySoc: "Reported Battery Percentage",
  calculatedBatterySoc: "Calculated Battery Percentage",
  batteryVoltage: "Battery Voltage",
  batteryCurrent: "Battery Current",
  batteryCharge: "Battery Charge",
  batteryDischarge: "Battery Discharge",

  generatorFrequency: "Generator Frequency",
  generatorVoltage: "Generator Voltage",
  generatorCurrent: "Generator Current",
  generatorPower: "Generator Power",

  loadPower: "Load Power",
  loadVoltage: "Load Voltage",
  loadCurrent: "Load Current",

  importPrice: "Import Price",
  exportPrice: "Export Price",

  evCurrent: "EV Current",
  evFrequency: "EV Frequency",
  evPower: "EV Power",
  evVoltage: "EV Voltage"
};

export const dataKeyToColour: Record<PotentialDataKeys, string> = {
  batteryCharge: LINE_COLOURS.YELLOW,
  batteryCurrent: LINE_COLOURS.CURRENT,
  batteryDischarge: LINE_COLOURS.BLUE,
  batteryPower: LINE_COLOURS.CYAN,
  batterySoc: LINE_COLOURS.CYAN,
  reportedBatterySoc: LINE_COLOURS.CYAN,
  calculatedBatterySoc: LINE_COLOURS.GREEN,
  batteryVoltage: LINE_COLOURS.VOLTAGE,
  generatorCurrent: LINE_COLOURS.CURRENT,
  generatorFrequency: LINE_COLOURS.HERTZ,
  generatorVoltage: LINE_COLOURS.VOLTAGE,
  generatorPower: LINE_COLOURS.LIGHT_GREEN,
  gridExport: LINE_COLOURS.GREEN,
  gridImport: LINE_COLOURS.PINK,
  gridPower: LINE_COLOURS.ORANGE,
  loadPower: LINE_COLOURS.YELLOW,
  loadCurrent: LINE_COLOURS.CURRENT,
  loadVoltage: LINE_COLOURS.VOLTAGE,
  pvCurrent: LINE_COLOURS.CURRENT,
  pvPower: LINE_COLOURS.PURPLE,
  pvFromDC: LINE_COLOURS.GREEN,
  pvFromAC: LINE_COLOURS.CURRENT,
  pvVoltage: LINE_COLOURS.VOLTAGE,
  importPrice: LINE_COLOURS.PINK,
  exportPrice: LINE_COLOURS.GREEN,
  evCurrent: LINE_COLOURS.CURRENT,
  evFrequency: LINE_COLOURS.HERTZ,
  evVoltage: LINE_COLOURS.VOLTAGE,
  evPower: LINE_COLOURS.EVPURPLE,
};

export const dataKeyToUnit: Record<PotentialDataKeys, string> = {
  batteryCharge: UNITS.KILOWATT,
  batteryCurrent: UNITS.AMP,
  batteryDischarge: UNITS.KILOWATT,
  batteryPower: UNITS.KILOWATT,
  batterySoc: UNITS.PERCENT,
  reportedBatterySoc: UNITS.PERCENT,
  calculatedBatterySoc: UNITS.PERCENT,
  batteryVoltage: UNITS.VOLT,
  generatorCurrent: UNITS.AMP,
  generatorFrequency: UNITS.HERTZ,
  generatorVoltage: UNITS.VOLT,
  gridExport: UNITS.KILOWATT,
  gridImport: UNITS.KILOWATT,
  gridPower: UNITS.KILOWATT,
  loadPower: UNITS.KILOWATT,
  loadCurrent: UNITS.AMP,
  loadVoltage: UNITS.VOLT,
  pvCurrent: UNITS.AMP,
  pvPower: UNITS.KILOWATT,
  pvFromDC: UNITS.KILOWATT,
  pvFromAC: UNITS.KILOWATT,
  pvVoltage: UNITS.VOLT,
  importPrice: UNITS.CENTS_PER_KILOWATT_HOUR,
  exportPrice: UNITS.CENTS_PER_KILOWATT_HOUR,
  generatorPower: UNITS.KILOWATT,
  evCurrent: UNITS.AMP,
  evFrequency: UNITS.HERTZ,
  evPower: UNITS.KILOWATT,
  evVoltage: UNITS.VOLT
};

export const powerProfileDataKeys: Array<PotentialDataKeys> =
  ["gridPower", "batteryPower", "loadPower", "pvPower", "evPower"];
export const priceProfileDataKeys: Array<PotentialDataKeys> = ["exportPrice", "importPrice"];

//this is gross but its an easy way to get an array of the keys in string form
const _allDataKeys: Record<PotentialDataKeys, null> = {
  batteryCharge: null,
  batteryCurrent: null,
  batteryDischarge: null,
  batteryPower: null,
  batterySoc: null,
  reportedBatterySoc: null,
  calculatedBatterySoc: null,
  batteryVoltage: null,
  generatorCurrent: null,
  generatorFrequency: null,
  generatorVoltage: null,
  gridExport: null,
  gridImport: null,
  gridPower: null,
  loadPower: null,
  loadCurrent: null,
  loadVoltage: null,
  pvCurrent: null,
  pvPower: null,
  pvFromAC: null,
  pvFromDC: null,
  pvVoltage: null,
  importPrice: null,
  exportPrice: null,
  generatorPower: null,
  evCurrent: null,
  evFrequency: null,
  evPower: null,
  evVoltage: null,
};
export const allPotentialDataKeys: Array<PotentialDataKeys> = Object.keys(_allDataKeys)
  .map((key) => key as PotentialDataKeys);