
import { Typography } from "@mui/material";
import house from "../../assets/img/house.png";
import factory from "../../assets/img/factory.png";

import styles from "./energy.module.scss";
import { RenderedRealtimeData } from "./constants";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React from "react";

function HouseSVG({ data }: { data: RenderedRealtimeData }): JSX.Element {
  const commonPathProps = {
    stroke: "darkgray",
    strokeWidth: 1,
    filter: "url(#shadow)",
  };

  const offgridPathProps = {
    stroke: "red",
    strokeWidth: 1,
    filter: "url(#shadow)",
    strokeDasharray: "5,10",
  };

  const animatedPathProps = (value?: number | boolean, reverseAnimation?: boolean) => {
    const dashArray = `1 ${Math.floor(Math.random() * (80 - 40 + 1)) + 40}`;
    const animationName = reverseAnimation ? "dashReverse" : "dash";

    return {
      stroke: "cyan",
      strokeWidth: 3,
      strokeLinecap: "round" as const,
      strokeDasharray: dashArray,
      style: {
        animation: value !== 0 && value !== undefined
          ?`${animationName} 30s linear infinite` : "none",
      },
    };
  };

  return (
    <svg
      width="376"
      height="400"
      viewBox="0 0 376 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex flex-shrink-0"
    >
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="3"
            floodColor="black"
            floodOpacity="0.2"
          />
        </filter>
        <style>{`
          @keyframes dash {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: 1500;
            }
          }

          @keyframes dashReverse {
            from {
              stroke-dashoffset: 1500;
            }
            to {
              stroke-dashoffset: 0;
            }
          }
        `}</style>
      </defs>

      {/* Solar Top Line */}
      <path d="M283 85 V 155 M300 1 H 300" {...commonPathProps} />
      {data?.PvPower !== 0 && data?.PvPower !== undefined && (
        <path d="M283 85 V 155 M300 1 H 300" {...animatedPathProps(data?.PvPower, true)} />
      )}

      {/* Solar Bottom Line */}
      <path d="M 190 175 A 15 25 0 0 0 180 190 V 258" {...commonPathProps} />
      {data?.PvPower !== 0 && data?.PvPower !== undefined && (
        <path d="M 190 175 A 15 25 0 0 0 180 190 V 258"
          {...animatedPathProps(data?.PvPower, true)} />
      )}

      {/* Battery Line */}
      <path d="M165 355 V 292 M300 1 H 1" {...commonPathProps} />
      {data?.BatteryPower !== 0 && data?.BatteryPower !== undefined && (
        <path d="M165 320 V 295 M300 1 H 1"
          {...animatedPathProps(data?.BatteryPower, (data?.BatteryPower as number || 0 ) < 0)} />
      )}

      {/* Load Line */}
      <path d="M 280 282 V 303 A 10 10 0 0 1 275 308 H 165" {...commonPathProps} />
      {data?.LoadPower !== 0 && data?.LoadPower !== undefined && (
        <path d="M 280 282 V 303 A 10 10 0 0 1 275 308 H 165"
          {...animatedPathProps(data?.LoadPower)} />
      )}

      {/* Grid Line */}
      {(data?.GeneratorVoltage as number || 0 ) < 100 &&
      (data?.GridPower === 0 || data?.GridPower === undefined) &&
      (data?.GeneratorPower === 0 || data?.GeneratorPower === undefined) ? (
          <path d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165" {...offgridPathProps} />
        ) : (
          <path d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165" {...commonPathProps} />
        )}
      {data?.GridPower !== 0 && data?.GridPower !== undefined && (
        <path d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165"
          {...animatedPathProps(data?.GridPower, (data?.GridPower as number || 0 ) > 0)} />
      )}

      {/* EV Line */}
      <path d="M 80 300 V 304 A 10 10 0 0 0 90 314 h 75" {...commonPathProps} />
      {data?.EVPower !== 0 && data?.EVPower !== undefined && (
        <path d="M 80 300 V 304 A 10 10 0 0 0 90 314 h 75" {...animatedPathProps(data?.EVPower)} />
      )}
    </svg>
  );
}

const MemoizedHouseSVG = React.memo(HouseSVG, (prevProps, nextProps) => {
  // Define the properties you want to compare
  const relevantProps: Array<keyof RenderedRealtimeData> = [
    "PvPower",
    "BatteryPower",
    "LoadPower",
    "GridPower",
    "EVPower",
    "GeneratorVoltage",
    "GeneratorPower",
  ];

  return relevantProps.every(
    (prop) => prevProps.data[prop] === nextProps.data[prop]
  );
});

function FactorySVG({ data }: { data: RenderedRealtimeData }): JSX.Element {
  // Common path properties
  const commonPathProps = {
    stroke: "darkgray",
    strokeWidth: 1,
    filter: "url(#shadow)",
  };

  // Offgrid path properties
  const offgridPathProps = {
    stroke: "red",
    strokeWidth: 1,
    filter: "url(#shadow)",
    strokeDasharray: "5,10",
  };

  // Animated path properties function
  const animatedPathProps = (value?: number | boolean, reverseAnimation?: boolean) => {
    const dashArray = `1 ${Math.floor(Math.random() * (80 - 40 + 1)) + 40}`;
    const animationName = reverseAnimation ? "dashReverse" : "dash";

    return {
      stroke: "cyan",
      strokeWidth: 3,
      strokeLinecap: "round" as const,
      strokeDasharray: dashArray,
      style: {
        animation: value !== 0 && value !== undefined
          ? `${animationName} 30s linear infinite`
          : "none",
      },
    };
  };

  return (
    <svg
      width="376"
      height="400"
      viewBox="0 0 376 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex flex-shrink-0"
    >
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="3"
            floodColor="black"
            floodOpacity="0.2"
          />
        </filter>
        <style>{`
          @keyframes dash {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: 1500;
            }
          }

          @keyframes dashReverse {
            from {
              stroke-dashoffset: 1500;
            }
            to {
              stroke-dashoffset: 0;
            }
          }
        `}</style>
      </defs>

      {/* Solar Top Line */}
      <path d="M283 85 V 155 M300 1 H 300" {...commonPathProps} />
      {data?.PvPower !== 0 && data?.PvPower !== undefined && (
        <path d="M283 85 V 155 M300 1 H 300" {...animatedPathProps(data?.PvPower, true)} />
      )}

      {/* Solar Bottom Line */}
      <path d="M 195 178 A 15 15 0 0 0 185 193 V 258 L 197 255" {...commonPathProps} />
      {data?.PvPower !== 0 && data?.PvPower !== undefined && (
        <path d="M 195 178 A 15 15 0 0 0 185 193 V 258 L 197 255"
          {...animatedPathProps(data?.PvPower, true)} />
      )}

      {/* Battery Line */}
      <path d="M 165 355 V 292 L 200 283" {...commonPathProps} />
      {data?.BatteryPower !== 0 && data?.BatteryPower !== undefined && (
        <path d="M 165 355 V 292 L 200 283"
          {...animatedPathProps(
            data?.BatteryPower,
            (data?.BatteryPower as number || 0 ) < 0
          )} />
      )}

      {/* Load Line */}
      <path
        d="M 310 255 V 280 H 293 V 303 A 10 10 0 0 1 285 308 H 165"
        {...commonPathProps}
      />
      {data?.LoadPower !== 0 && data?.LoadPower !== undefined && (
        <path
          d="M 310 255 V 280 H 293 V 303 A 10 10 0 0 1 285 308 H 165"
          {...animatedPathProps(data?.LoadPower)}
        />
      )}

      {/* Grid Line */}
      {(data?.GeneratorVoltage as number || 0 ) < 100 &&
        (data?.GridPower === 0 || data?.GridPower === undefined) &&
        (data?.GeneratorPower === 0 || data?.GeneratorPower === undefined) ? (
          <path
            d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165"
            {...offgridPathProps}
          />
        ) : (
          <path
            d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165"
            {...commonPathProps}
          />
        )}
      {data?.GridPower !== 0 && data?.GridPower !== undefined && (
        <path
          d="M 280 354 V 325 A 9 9 0 0 0 275 320 H 165"
          {...animatedPathProps(data?.GridPower, (data?.GridPower as number || 0 ) > 0)}
        />
      )}

      {/* EV Line */}
      <path d="M 60 290 V 304 A 10 10 0 0 0 70 314 h 95" {...commonPathProps} />
      {data?.EVPower !== 0 && data?.EVPower !== undefined && (
        <path d="M 60 290 V 304 A 10 10 0 0 0 70 314 h 95" {...animatedPathProps(data?.EVPower)} />
      )}
    </svg>
  );
}

const MemoizedFactorySVG = React.memo(FactorySVG, (prevProps, nextProps) => {
  // Define the properties you want to compare
  const relevantProps: Array<keyof RenderedRealtimeData> = [
    "PvPower",
    "BatteryPower",
    "LoadPower",
    "GridPower",
    "EVPower",
    "GeneratorVoltage",
    "GeneratorPower",
  ];

  return relevantProps.every(
    (prop) => prevProps.data[prop] === nextProps.data[prop]
  );
});

function CorePowerFlowComponent({ data }: { data: RenderedRealtimeData }): JSX.Element {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      height: 30,
      backgroundColor: theme.palette.mode === "light" ? "purple" : "#308fe8",
    },
  }));

  interface BatteryIndicatorProps {
    value: number; // Assuming value is a number between 0 and 100
  }

  const BatteryIndicator: React.FC<BatteryIndicatorProps> = ({ value }) => {
    const getColorForValue = (value: number): string => {
      // Calculate the color gradient from red to green based on value
      const percent = value / 100;
      const red =
        percent < 0.5 ? 255 : Math.floor(255 - (percent - 0.5) * 2 * 255);
      const green = percent > 0.5 ? 255 : Math.floor(percent * 2 * 255);
      return `rgb(${red}, ${green}, 0)`;
    };

    return (
      <BorderLinearProgress
        variant="determinate"
        value={value}
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: getColorForValue(value),
          },
        }}
      />
    );
  };

  interface BatteryProps {
    data: RenderedRealtimeData;
  }

  const BatteryRemaining: React.FC<BatteryProps> = ({ data }) => {
    // Calculate remaining capacity in kWh
    const remainingCapacity =
      (((data.Capacity as number || 0 ) * (data.SOC as number || 0 )) / 100) *
      ((data.SOC as number || 0 ) / 100);

    const capacityToFull = (data.Capacity as number || 0 ) - remainingCapacity;

    let hoursToEmpty = NaN;
    let hoursToFull = NaN;

    const batteryPower = data.BatteryPower as number || 0;

    if (batteryPower > 0) {
      hoursToEmpty = remainingCapacity / batteryPower;
    } else if (batteryPower < 0) {
      hoursToFull = capacityToFull / Math.abs(batteryPower);
    }
    return (
      <div>
        {data.Capacity === undefined ? (
          <Typography>Hours left - Unknown</Typography>
        ) : !isNaN(hoursToEmpty) ? (
          <Typography variant="caption">
            You have <span style={{ color: "cyan" }}>{hoursToEmpty.toFixed(2)}</span>{" "}
            hours of charge left
          </Typography>
        ) : !isNaN(hoursToFull) ? (
          <Typography>
            Your battery will be topped up in{" "}
            <span style={{ color: "cyan" }}>{hoursToFull.toFixed(2)}</span>{" "}
            hours
          </Typography>
        ) : (
          <Typography>Keep it up! You're a self sufficiency machine</Typography>
        )}
      </div>
    );
  };

  return (
    <div>
      {data?.ConfigType !== "deye-dingo-v1" ? (
        <div className={styles.houseContainer}>
          <img src={house} alt="House" className={styles.image} />
          <div className={styles.svgContainer}>
            <MemoizedHouseSVG data={data} />
          </div>
        </div>
      ) : (
        <div className={styles.factoryContainer}>
          <img src={factory} alt="Factory" className={styles.image} />
          <div className={styles.svgContainer}>
            <MemoizedFactorySVG data={data} />
          </div>
        </div>)}
      <div>
        <div
          style={{
            marginTop: "50px",
            marginLeft: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <Typography variant="h5" style={{ paddingRight: "5px" }}>
            {(data?.SOC || "--") + "%"}
          </Typography>
          <Typography variant="caption">remaining battery capacity</Typography>
        </div>
        <div style={{ padding: "5px 15px" }}>
          <BatteryIndicator value={data?.SOC as number || 0 } />
        </div>
        <div
          style={{
            marginLeft: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <BatteryRemaining data={data} />
        </div>
      </div>
    </div>
  );
}

export default CorePowerFlowComponent;