/* eslint-disable max-len */
import { defaultPowerDataValue, defaultPriceDataValue, makeAsyncDataObject } from "../util/general";
import { CustomAppState } from "../types/general";
import { IAppData, IFirebaseClaims, IUserSettings } from "../types/redux";
import { INotificationPermissions } from "../types/notification";
import { PurchaseInfo, ProductId } from "./../types/products";
import { CachedDataIntervals } from "../types/server";

export const defaultFirebaseClaims: IFirebaseClaims = {
  internalUser: false
};

export const defaultNotificationPermissions: INotificationPermissions = {
  achievements: true,
  batteryPercentages: true,
  forecast: true,
  outcomes: true,
  trading: true,
  updates: true,
  weatherForecasts: true,
  data: {
    batteryPercentages: {
      percentage: 0
    }
  }
};
// /////////////////////////////
const defaultProductItems: Record<ProductId, PurchaseInfo | undefined> = {
  "trading": undefined,
  "optimum": undefined,
  "powerRanger": undefined,
  "smartEvCharging": undefined
};

export const initialState: IAppData = {
  authUser: null,
  settings: null,
  appState: CustomAppState.active,
  plants: makeAsyncDataObject({}),
  demoPlants: makeAsyncDataObject({}),
  possiblePlants: makeAsyncDataObject({}),
  alertList: makeAsyncDataObject([]),
  currentPlantId: undefined,
  lastCurrentPlantUpdateEpoch:undefined,
  powerData: defaultPowerDataValue(),
  priceData: defaultPriceDataValue(),
  financialData: makeAsyncDataObject({}),
  environmentalData: makeAsyncDataObject({}),
  forceDataRefresh: 0,
  firebaseClaims: defaultFirebaseClaims,
  uiFlags: {
    "dismissedDemoPlantsWarning": false,
    "homePageStateIsGraph": true,
    "purchaseListenerInProgress": false,
    "lastWarrantyReminderTime": 0,
  },
  aggregatePlantData: makeAsyncDataObject({}),
  cumulativeFinancialData: makeAsyncDataObject({}),
  cumulativeEnvironmentalData: makeAsyncDataObject({}),
  notifications: makeAsyncDataObject([]),
  notificationPermissions: makeAsyncDataObject(defaultNotificationPermissions),
  userPersonalDetails: makeAsyncDataObject({}),
  challenges: makeAsyncDataObject({}),
  tradingHistory: makeAsyncDataObject({}),
  products: makeAsyncDataObject(defaultProductItems),
  tradingStatus: makeAsyncDataObject({}),
  accessories: makeAsyncDataObject({}),
  sims: makeAsyncDataObject({}),
  schedules: makeAsyncDataObject({}),
};

export const generateDefaultCachedDataObjectForPlant = () => {
  return {
    [CachedDataIntervals.Daily]: {},
    [CachedDataIntervals.Monthly]: {},
    [CachedDataIntervals.Yearly]: {}
  };
};

export enum FinancialValueKeys {
  ELECTRICITY_PURCHASES = "ELECTRICITY_PURCHASES",
  SELF_CONSUMPTION = "SELF_CONSUMPTION",
  ENERGY_TRADING = "ENERGY_TRADING"
}

//TODO remove once financial impact card is removed
export const FINANCIAL_VALUES_KEYS: Record<FinancialValueKeys, string> = {
  [FinancialValueKeys.ELECTRICITY_PURCHASES]: "ELECTRICITY_PURCHASES",
  [FinancialValueKeys.SELF_CONSUMPTION]: "SELF_CONSUMPTION",
  [FinancialValueKeys.ENERGY_TRADING]: "ENERGY_TRADING",
};

export const financialValueKeyToSemanticName: Record<FinancialValueKeys, string> = {
  [FinancialValueKeys.ELECTRICITY_PURCHASES]: "Electricity Purchases",
  [FinancialValueKeys.SELF_CONSUMPTION]: "Savings",
  [FinancialValueKeys.ENERGY_TRADING]: "Export Earnings",
};

export const defaultSettings: IUserSettings = {
  lastRoute: null,
  lastPlantID: null,
  showDemoPlants: false,
  dismissedNotifications: [],
};

export enum EnergyProductionKeys {
  SOLAR = "SOLAR",
  GRID_IMPORT = "GRID_IMPORT",
  GRID_EXPORT = "GRID_EXPORT",
  BATTERY_CHARGING = "BATTERY_CHARGING",
  BATTERY_DISCHARGING = "BATTERY_DISCHARGING",
  USAGE = "USAGE",
  GENERATOR = "GENERATOR",
  EV_CHARGING = "EV_CHARGING",
  BATTERY_SOC = "BATTERY_SOC",
}

export const ENERGY_PRODUCTION_KEYS: Record<EnergyProductionKeys, string> = {
  SOLAR: "SOLAR",
  GRID_IMPORT: "GRID_IMPORT",
  GRID_EXPORT: "GRID_EXPORT",
  BATTERY_CHARGING: "BATTERY_CHARGING",
  BATTERY_DISCHARGING: "BATTERY_DISCHARGING",
  USAGE: "USAGE",
  GENERATOR: "GENERATOR",
  EV_CHARGING: "EV_CHARGING",
  BATTERY_SOC: "BATTERY_SOC"
};

export const ENERGY_PRODUCTION_SEMANTIC_NAMES: Record<EnergyProductionKeys, string> = {
  SOLAR: "Solar",
  GRID_IMPORT: "Grid Import",
  GRID_EXPORT: "Grid Export",
  BATTERY_CHARGING: "Battery Filling",
  BATTERY_DISCHARGING: "Battery Emptying",
  USAGE: "Usage",
  GENERATOR: "Generator",
  EV_CHARGING: "EV Charging",
  BATTERY_SOC: "Battery %"
};

export enum EnergyPriceKeys {
  IMPORT_PRICE = "IMPORT_PRICE",
  EXPORT_PRICE = "EXPORT_PRICE"

}

export const PRICE_CHART_KEYS: Record<EnergyPriceKeys, string> = {
  IMPORT_PRICE: "IMPORT_PRICE",
  EXPORT_PRICE: "EXPORT_PRICE"
};

export const PRICE_CHART_SEMANTIC_NAMES = {
  IMPORT_PRICE: "Import Price",
  EXPORT_PRICE: "Export Price"
};