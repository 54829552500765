import { selectCurrentPlant } from "apps-middleware/redux/selectors/plants";
import { RootState } from "apps-middleware/redux/store/configureStore";
import { PowerIntervalType, PriceIntervalType, UnixTimeStamp } from "apps-middleware/types/redux";
import {
  potentiallyNullRoundToTwoDecimals,
  potentiallyNullWattToKiloWatt
} from "apps-middleware/util/general";
import { isPlantData, isTradingHistory } from "apps-middleware/util/type";
import { allPotentialDataKeys } from "constants/graph";
import { DateTime } from "luxon";
import { ITimeScaledGraphData, PotentialDataKeys } from "types/graph";

export const getGraphDataForCurrentPlant = (state: RootState): {
  combinedData: ITimeScaledGraphData[],
  dataKeysWithData: Array<PotentialDataKeys>
} => {
  const currentPlant = selectCurrentPlant(state);
  const combinedData: Record<UnixTimeStamp, ITimeScaledGraphData> = {};
  const preferredPowerInterval: PowerIntervalType = 5;
  const preferredPriceInterval: PriceIntervalType = 5;
  /**
   * What is dataKeyIsNullTracker?
   * An object which just keeps a boolean of if, of the selected range of data
   * does any of the points of each dataKey have valid data.
   * If no data for a dataKey is present at all the boolean will be false
   * If at least 1 point of data for the dataKey !== null then boolean will be true
   */
  const dataKeyIsNullTracker: Array<PotentialDataKeys> = [];
  if (!currentPlant) return {
    combinedData: Object.values(combinedData),
    dataKeysWithData: dataKeyIsNullTracker
  };

  const powerData = state.appData.powerData.data[currentPlant.plantId]
    ? state.appData.powerData.data[currentPlant.plantId][preferredPowerInterval] : {};
  const priceData = state.appData.priceData.data[currentPlant.plantId]
    ? state.appData.priceData.data[currentPlant.plantId][preferredPriceInterval] : {};
  const priceDataForcast = state.appData.priceData.data[currentPlant.plantId]
    ? state.appData.priceData.data[currentPlant.plantId][30] : {};
  const tradingHistory = state.appData.tradingHistory.data[currentPlant.plantId] ?? {};
  const tradingHistoryArray = Object.values(tradingHistory)
    .sort((a, b) => a.timestamp - b.timestamp);

  [...Object.keys(powerData), ...Object.keys(priceData), ...Object.keys(priceDataForcast)]
    .sort()//make sure the data is sorted in ascending order
    .forEach((key) => {
      const unixTimestamp = parseInt(key);

      if (!unixTimestamp) return;

      const row: ITimeScaledGraphData = { unixTimestamp: unixTimestamp };
      combinedData[unixTimestamp] = row;

      if (powerData && powerData[unixTimestamp]) {
        const row = powerData[unixTimestamp];
        combinedData[unixTimestamp] = {
          ...combinedData[unixTimestamp],
          "batteryPower": potentiallyNullWattToKiloWatt(row.batteryPower),
          "batteryCharge": potentiallyNullWattToKiloWatt(row.batteryCharge),
          "batteryDischarge": potentiallyNullWattToKiloWatt(row.batteryDischarge),
          "batteryCurrent": potentiallyNullRoundToTwoDecimals(row.batteryCurrent),
          "batteryVoltage": potentiallyNullRoundToTwoDecimals(row.batteryVoltage),
          "batterySoc": potentiallyNullRoundToTwoDecimals(row.batterySoc),
          "reportedBatterySoc": potentiallyNullRoundToTwoDecimals(row.reportedBatterySoc),
          "calculatedBatterySoc": potentiallyNullRoundToTwoDecimals(row.calculatedBatterySoc),

          "gridPower": potentiallyNullWattToKiloWatt(row.gridPower),
          "gridImport": potentiallyNullWattToKiloWatt(row.gridImport),
          "gridExport": potentiallyNullWattToKiloWatt(row.gridExport),

          "pvPower": potentiallyNullWattToKiloWatt(row.pvPower),
          "pvFromDC": potentiallyNullWattToKiloWatt(row.pvFromDC),
          "pvFromAC": potentiallyNullWattToKiloWatt(row.pvFromAC),
          "pvCurrent": potentiallyNullRoundToTwoDecimals(row.pvCurrent),
          "pvVoltage": potentiallyNullRoundToTwoDecimals(row.pvVoltage),

          "loadVoltage": potentiallyNullRoundToTwoDecimals(row.loadVoltage),
          "loadCurrent": potentiallyNullRoundToTwoDecimals(row.loadCurrent),
          "loadPower": potentiallyNullWattToKiloWatt(row.loadPower),

          "generatorCurrent": potentiallyNullRoundToTwoDecimals(row.generatorCurrent),
          "generatorFrequency": potentiallyNullRoundToTwoDecimals(row.generatorFrequency),
          "generatorVoltage": potentiallyNullRoundToTwoDecimals(row.generatorVoltage),
          "generatorPower": potentiallyNullWattToKiloWatt(row.generatorPower),

          "evCurrent": potentiallyNullRoundToTwoDecimals(row.evCurrent),
          "evFrequency": potentiallyNullRoundToTwoDecimals(row.evFrequency),
          "evVoltage": potentiallyNullRoundToTwoDecimals(row.evVoltage),
          "evPower": potentiallyNullWattToKiloWatt(row.evPower),
        };
      }
      if (priceData && priceData[unixTimestamp]) {
        combinedData[unixTimestamp] = {
          ...combinedData[unixTimestamp],
          "importPrice": potentiallyNullRoundToTwoDecimals(priceData[unixTimestamp].importPrice),
          "exportPrice": potentiallyNullRoundToTwoDecimals(priceData[unixTimestamp].exportPrice),
        };
      }

      if (
        priceDataForcast &&
        priceDataForcast[unixTimestamp] &&
        unixTimestamp > DateTime.now().toMillis()
      ) {
        combinedData[unixTimestamp] = {
          ...combinedData[unixTimestamp],
          "importPrice": potentiallyNullRoundToTwoDecimals(
            priceDataForcast[unixTimestamp].importPrice
          ),
          "exportPrice": potentiallyNullRoundToTwoDecimals(
            priceDataForcast[unixTimestamp].exportPrice
          ),
        };
      }

      const i = tradingHistoryArray.findIndex((history) => history.timestamp > unixTimestamp);
      if (
        i > 0 &&
        i - 1 < tradingHistoryArray.length &&
        isTradingHistory(tradingHistoryArray[i - 1])
      ) {
        combinedData[unixTimestamp]["mostRecentCommand"] = tradingHistoryArray[i - 1];
      }

      allPotentialDataKeys.forEach((dataKey) => {
        if (!dataKeyIsNullTracker.includes(dataKey) &&
          combinedData[unixTimestamp][dataKey] !== undefined &&
          combinedData[unixTimestamp][dataKey] !== null) {
          dataKeyIsNullTracker.push(dataKey);
        }
      });

    });

  return {
    combinedData: Object.values(combinedData),
    dataKeysWithData: dataKeyIsNullTracker,
  };
};

export const getAggregateGraphData = (state: RootState): ITimeScaledGraphData[] => {
  const result: ITimeScaledGraphData[] = [];
  if (!state.appData.aggregatePlantData || !state.appData.aggregatePlantData.data) return result;

  const allData = Object.values(state.appData.aggregatePlantData.data);

  if (
    !Array.isArray(allData) ||
    (Array.isArray(allData) && allData.length === 0)
  ) return result;

  allData.sort(
    (a, b) =>
      (a.unixTimestamp && b.unixTimestamp) ? a.unixTimestamp - b.unixTimestamp : 0);

  const mostRecentDataPoint: UnixTimeStamp | null = allData[allData.length - 1].unixTimestamp;

  let cutOffPoint: DateTime = (mostRecentDataPoint !== null)
    ? DateTime.fromMillis(mostRecentDataPoint) : DateTime.now();
  cutOffPoint = cutOffPoint.endOf("minute").minus({ "minutes": 15 });

  Object.values(state.appData.aggregatePlantData.data).forEach((row) => {
    if (
      !isPlantData(row) ||
      row.unixTimestamp === null ||
      row.unixTimestamp > cutOffPoint.toMillis()
    ) return;

    result.push({
      unixTimestamp: row.unixTimestamp,
      "pvPower": potentiallyNullWattToKiloWatt(row.pvPower),
      "batteryPower": potentiallyNullWattToKiloWatt(row.batteryPower),
      "batterySoc": potentiallyNullRoundToTwoDecimals(row.batterySoc),
      "reportedBatterySoc": potentiallyNullRoundToTwoDecimals(row.reportedBatterySoc),
      "calculatedBatterySoc": potentiallyNullRoundToTwoDecimals(row.calculatedBatterySoc),
      "loadPower": potentiallyNullWattToKiloWatt(row.loadPower),
      "gridPower": potentiallyNullWattToKiloWatt(row.gridPower),
      "evPower": potentiallyNullWattToKiloWatt(row.evPower),
    });
  });

  return result;
};