import { DateTime } from "luxon";
import {
  GetUIFriendlyDateString,
  GetUIFriendlyTimeString,
  isSameDate,
} from "apps-middleware/util/time";
import ChartTooltip from "components/ChartTooltip/ChartTooltip";

import React, { useCallback, useState } from "react";
import {
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import styles from "./TimeScaledLineChart.module.scss";

import blinkingLogo from "assets/img/RedEarthLogoBlinking.svg";
import { IDateRange } from "apps-middleware/types/time";
import { ITimeScaledGraphData, PotentialDataKeys } from "types/graph";
import { AxisDomain } from "recharts/types/util/types";
import { dataKeyToColour, dataKeyToUnit, dataKeyToSemanticName } from "constants/graph";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { TradingChoices } from "apps-middleware/types/trading";
import { palette } from "apps-middleware/constants/palette";

const LEFT_Y_AXIS_ID = "yAxisLeftID";
const RIGHT_Y_AXIS_ID = "yAxisRightID";
const margin = { left: 20, right: 20, top: 0, bottom: 10 };

export interface ITimeScaleLineChart {
  graphHeight: number | string;
  isLoading: boolean,
  data:
  | Array<ITimeScaledGraphData>;
  dataKeysToConsider?: Array<PotentialDataKeys>;
  rightYAxis?: {
    dataKey: Array<PotentialDataKeys>,
    domain?: AxisDomain,
    label?: string,
  },
  leftYAxis: {
    dataKey: Array<PotentialDataKeys>,
    domain?: AxisDomain
    label?: string,
  },
  defaultHiddenDataKeys?: Array<PotentialDataKeys>,
  plotBounds: IDateRange;
  aspect?: number | undefined,
  zoomedDomain: IDateRange | null;
  setZoomedDomain: (bounds: IDateRange | null) => void;
  syncId?: string;
  backgroundColour?: string,
  tradingChoices?: TradingChoices
}

const _TimeScaleLineChart = ({
  graphHeight,
  data,
  plotBounds,
  zoomedDomain,
  setZoomedDomain,
  leftYAxis,
  rightYAxis,
  defaultHiddenDataKeys,
  isLoading,
  aspect = undefined,
  dataKeysToConsider,
  syncId,
  backgroundColour,
  tradingChoices
}: ITimeScaleLineChart): JSX.Element | null => {

  const [refInitial, setRefInitial] = useState<number | null>(null);
  const [refSecondary, setRefSecondary] = useState<number | null>(null);
  const [refHover, setRefHover] = useState<number | null>(null);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const animationDone = useCallback(() => {
    setAnimationPlayed(true);
  }, []);
  /**
   * We are tracking our own isHovering to make sure the tool tip only
   * renders its custom UI when for the chart that actually is being hovered
   * on. Due to the syncId being given on all of our charts if we let the
   * tooltip always render its UI the same tooltip UI would appear for all
   * of the charts. We only want the line so we instead just throw an empty
   * bit of JSX so nothing is rendered
   */
  const [isMouseHovering, setIsMouseHovering] = useState<number | undefined>(undefined);
  const initialTime = DateTime.now();

  const [linesActiveStatus, setLinesActiveStatus] = useState<Record<string, boolean>>(() => {
    const lines: Record<string, boolean> = {};
    [
      ...leftYAxis.dataKey,
      ...(rightYAxis ? rightYAxis.dataKey : [])
    ]
      .forEach((lineDataKey) => {
        let show = true;
        if (defaultHiddenDataKeys) {
          show = !defaultHiddenDataKeys.includes(lineDataKey);
        }
        lines[lineDataKey] = show;
      });
    return lines;
  });

  const handleLegendClick = useCallback((e) => {
    const changingState = { ...linesActiveStatus };
    changingState[e.dataKey] = !linesActiveStatus[e.dataKey];
    setLinesActiveStatus(changingState);
  }, [linesActiveStatus]);

  const handleMouseDown = useCallback((nextState: CategoricalChartState) => {
    if (nextState && nextState.activeLabel) {
      if (refInitial === null) {
        setRefInitial(parseInt(nextState.activeLabel));
      } else {
        setRefSecondary(parseInt(nextState.activeLabel));
      }
    }
  }, [refInitial]);

  const handleMouseMove = useCallback((newState: CategoricalChartState) => {
    if (newState.activeLabel) setIsMouseHovering(parseInt(newState.activeLabel));
    if (newState && newState.activeLabel) {
      const timestamp = parseInt(newState.activeLabel);
      if (refInitial) setRefHover(timestamp);
    }
  }, [refInitial]);

  const handleMouseLeave = useCallback(() => {
    if (isMouseHovering) setIsMouseHovering(undefined);
  }, [isMouseHovering]);

  const handleMouseUp = useCallback(() => {
    if (refInitial === null || refSecondary === null) return;

    const bounds = refInitial > refSecondary // make the greater of the two points the "left" value
      ? { left: refSecondary, right: refInitial } : { left: refInitial, right: refSecondary };

    setRefInitial(null);
    setRefSecondary(null);
    setRefHover(null);
    if (bounds.left !== bounds.right) {
      setZoomedDomain({
        startDateTime: DateTime.fromMillis(bounds.left),
        endDateTime: DateTime.fromMillis(bounds.right)
      });
    }
  }, [refInitial, refSecondary]);

  // Create the custom ticks for the X Axis
  let xAxisTop = plotBounds.endDateTime;
  let xAxisBottom = plotBounds.startDateTime;
  let boundsForXAxis = plotBounds;

  // if there is a zoomed domain use those bounds set instead
  if (zoomedDomain) {
    xAxisBottom = zoomedDomain.startDateTime;
    xAxisTop = zoomedDomain.endDateTime;
    boundsForXAxis = zoomedDomain;
  }

  const ticks: number[] = [];
  let tDT: DateTime = xAxisTop.set({ minute: 0, second: 0, millisecond: 0 });
  while (tDT >= xAxisBottom) {
    ticks.push(tDT.toMillis());
    tDT = tDT.minus({ hour: 1 });
  }

  // Create an Additional Axis that will provide date info if a chart range spans >1 day.
  let additionalDateXAxis: JSX.Element | null = null;

  //check if the start/end are on different dates
  if (!isSameDate(xAxisTop, xAxisBottom)) {
    const additionalTicks: number[] = [];

    let tDT: DateTime = xAxisTop.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    while (tDT >= xAxisBottom) {
      additionalTicks.push(tDT.toMillis());
      tDT = tDT.minus({ days: 1 });
    }

    additionalDateXAxis = (
      <XAxis
        dataKey="name"
        stroke="#ffffff"
        tickMargin={15}
        axisLine={false}
        scale="time"
        type="number"
        domain={[boundsForXAxis.startDateTime.toMillis(), boundsForXAxis.endDateTime.toMillis()]}
        tickFormatter={(label) =>
          GetUIFriendlyDateString(
            DateTime.fromMillis(label),
            xAxisTop.year !== xAxisBottom.year
          )}
        ticks={additionalTicks}
        tickLine={false}
        xAxisId={1}
      />
    );
  }

  const filteredData = data.filter((x) =>
    x.unixTimestamp &&
    x.unixTimestamp >= boundsForXAxis.startDateTime.toMillis() &&
    x.unixTimestamp <= boundsForXAxis.endDateTime.toMillis()
  );
  const currentRow = data.find((r) => r.unixTimestamp === isMouseHovering);

  return (
    <div
      className={`${styles.container} ${isLoading ? styles.loading : ""}`}>
      {isLoading
        ? <div className={styles.loadingElement}>
          <img src={blinkingLogo} alt="loading logo" />
          <p>Loading Data</p>
        </div> : null}

      <ResponsiveContainer width="99%" height={graphHeight} aspect={aspect}>
        <LineChart
          syncId={syncId}
          margin={margin}
          data={filteredData}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}>
          {
            backgroundColour &&
            <ReferenceArea
              isFront={false}
              fill={backgroundColour}
              fillOpacity={1}
              x1={boundsForXAxis.endDateTime.toMillis()}
              x2={boundsForXAxis.startDateTime.toMillis()}
              yAxisId={LEFT_Y_AXIS_ID}
              xAxisId={0}
            />
          }
          {
            tradingChoices &&
            tradingChoices.successfulTrades &&
            tradingChoices.successfulTrades.map((spot, i) => {
              return <ReferenceArea
                key={"trading" + i}
                isFront={false}
                fill={palette.secondary.green.light}
                fillOpacity={0.4}
                x1={spot.range.startDateTime.toMillis()}
                x2={spot.range.endDateTime.toMillis()}
                yAxisId={LEFT_Y_AXIS_ID}
                xAxisId={0}
              />;
            })
          }
          {
            isMouseHovering &&
            currentRow &&
            currentRow.mostRecentCommand &&
            tradingChoices &&
            <ReferenceLine
              x={currentRow?.mostRecentCommand?.timestamp}
              yAxisId={LEFT_Y_AXIS_ID}
              isFront
              stroke={palette.neutral.B3}
              strokeWidth={2}
              strokeDasharray="10 5"
              ifOverflow="hidden"
            />
          }
          {
            tradingChoices &&
            tradingChoices.tradingCommandFails &&
            tradingChoices.tradingCommandFails.map((spot, i) => {
              return <ReferenceLine
                x={spot.toMillis()}
                yAxisId={LEFT_Y_AXIS_ID}
                isFront
                key={"failedTrading" + i}
                stroke={palette.secondary.orange.primary}
                strokeWidth={2}
                strokeDasharray="15 5"
                ifOverflow="hidden"
              />;
            })
          }
          {
            tradingChoices &&
            tradingChoices.stopTradingCommandFails &&
            tradingChoices.stopTradingCommandFails.map((spot, i) => {
              return <ReferenceLine
                x={spot.toMillis()}
                yAxisId={LEFT_Y_AXIS_ID}
                isFront
                key={"failedStopTrading" + i}
                stroke={palette.secondary.orange.primary}
                strokeWidth={2}
                strokeDasharray="15 5"
                ifOverflow="hidden"
              />;
            })
          }
          <Legend
            formatter={(value: PotentialDataKeys) => (
              <span style={!linesActiveStatus[value] ? { color: "grey" } : {}
              }>{dataKeyToSemanticName[value]}</span>)
            }
            verticalAlign="top"
            wrapperStyle={{ top: 0 }}
            align="center"
            onClick={handleLegendClick}
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            stroke="#d3d3d3"
            dataKey={(x: ITimeScaledGraphData) => x.unixTimestamp}
            domain={[
              boundsForXAxis.startDateTime.toMillis(),
              boundsForXAxis.endDateTime.toMillis()
            ]}
            type="number"
            scale="time"
            orientation="bottom"
            tickFormatter={(value) => GetUIFriendlyTimeString(DateTime.fromMillis(value))}
            minTickGap={15}
            tickMargin={15}
            ticks={ticks}
            xAxisId={0}
          />
          {additionalDateXAxis}
          {/* Places the now label at the bottom */}
          {
            //check to make sure the date line should be shown. If it is outside the range
            // we don't need it.
            initialTime >= boundsForXAxis.startDateTime &&
              // we minus 5 minutes from now here because technically selecting # hours a go will
              // result in a top end range that is immediately stale until the page refreshes.
              // this means the now will be out of range by a few milliseconds instantly as it refreshes
              // before the provided range. So we just give a 5 minute buffer which is reasonable.
              // so we still see the now indicator on the left.
              DateTime.now().minus({ minutes: 5 }) <= boundsForXAxis.endDateTime
              ? <XAxis
                dataKey={"name"}
                stroke="#ff0000"
                tickMargin={additionalDateXAxis ? -15 : 10}
                axisLine={false}
                scale="time"
                type="number"
                domain={[
                  boundsForXAxis.startDateTime.toMillis(),
                  boundsForXAxis.endDateTime.toMillis()
                ]}
                tickFormatter={() => "NOW"}
                ticks={[initialTime.toMillis()]}
                tickLine={false}
                xAxisId={3}
              /> : null
          }
          <YAxis
            yAxisId={LEFT_Y_AXIS_ID}
            scale="linear"
            domain={leftYAxis.domain ? leftYAxis.domain : ["dataMin", "dataMax"]}
            orientation="left"
            stroke="#d3d3d3"
            label={{
              value: leftYAxis.label ? leftYAxis.label : dataKeyToUnit[leftYAxis.dataKey[0]],
              angle: -90,
              dy: 0,
              position: "left",
              fill: "#d3d3d3"
            }}
          />
          {rightYAxis
            ? <YAxis
              yAxisId={RIGHT_Y_AXIS_ID}
              domain={rightYAxis.domain ? rightYAxis.domain : ["dataMin", "dataMax"]}
              orientation="right"
              scale="auto"
              stroke="#d3d3d3"
              label={{
                value: rightYAxis.label ? rightYAxis.label : dataKeyToUnit[rightYAxis.dataKey[0]],
                angle: -90,
                dy: 15,
                position: "right",
                fill: "red",
              }}
            /> : null}
          <Tooltip
            offset={70}
            allowEscapeViewBox={{ y: true, x: false }}
            content={
              isMouseHovering !== undefined
                ? <ChartTooltip
                  fullData={data}
                  dataKeysToConsider={dataKeysToConsider} />
                : <></>
            }
          />
          {
            refInitial !== null &&
            refHover !== null &&
            <ReferenceArea
              x1={refInitial}
              x2={refHover}
              stroke="red"
              yAxisId={LEFT_Y_AXIS_ID}
              xAxisId={0}
              strokeOpacity={0.5}
            />
          }
          <ReferenceArea
            x1={initialTime.minus({ hours: 1 }).toMillis()}
            x2={initialTime.plus({ hours: 1 }).toMillis()}
            strokeOpacity={1}
          />
          {/* Draws the "now" line */}
          <ReferenceLine
            x={initialTime.toMillis()}
            yAxisId={LEFT_Y_AXIS_ID}
            isFront
            stroke="red"
            strokeWidth={2}
            strokeDasharray="3 3"
            ifOverflow="hidden"
          />
          {
            !isLoading &&
            [
              ...leftYAxis.dataKey,
              ...(rightYAxis ? rightYAxis.dataKey : [])
            ]
              .map((dataKey, i) => (
                <Line
                  yAxisId={
                    leftYAxis.dataKey.includes(dataKey)
                      ? LEFT_Y_AXIS_ID : RIGHT_Y_AXIS_ID}
                  dot={false}
                  stroke={dataKeyToColour[dataKey]}
                  name={dataKey}
                  dataKey={dataKey}
                  key={i}
                  type="monotone"
                  strokeOpacity={linesActiveStatus[dataKey] ? 1 : 0}
                  strokeWidth={1.5}
                  isAnimationActive={!animationPlayed}
                  animationDuration={250}
                  onAnimationEnd={animationDone}
                />
              ))
          }
        </LineChart>
      </ResponsiveContainer>
    </div >
  );
};

export const TimeScaleLineChart = React.memo(_TimeScaleLineChart);