/* eslint-disable max-len */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UTC_DATE_FORMAT } from "../util/time";
import { RootState } from "./store/configureStore";
import {
  CachedDataIntervals,
  ErrorBody,
  GetRedPiDevicesQueryParams,
  GetRedPiLogsQueryParams,
  IAggregatePlantRequestParams,
  IAggregatePlantResponse,
  ICumulativeDailyFinancialDataResponse,
  ICumulativeEnvironmentalDataResponse,
  ICumultiveDailyDataParams,
  IFetchAlertsQueryParams,
  IFetchAlertsResponse,
  IFetchNotificationsResponse,
  IPlantGetDataV2Params,
  IUserPersonalDetails,
  PostRedPiCommandBody,
  ServerResponse,
  V3PlantsListQueryparams,
} from "../types/server";
import {
  PhysicalPlantId,
  PlantId
} from "../types/plant";
import { fetchPriceDataForPlant } from "../api/price";
import {
  fetchAggregatePlantData,
  fetchCumulativeEnvironmentalData,
  fetchCumulativeFinancialData,
  fetchEnvironmentalData,
  fetchFinancialData,
  fetchPlantData,
  fetchPlantInfo,
  fetchTradingHistory,
  fetchTradingStatus,
  fetchUserPlants
} from "../api/plant";
import {
  fetchPowerDataThunkResponse,
  fetchPriceDataThunkResponse,
  IFirebaseClaims,
  IPlantInfoThunkResponse,
  IPlantThunkResponse,
  IPowerDataThunkParams,
  IPriceDataThunkParams,
  RedPiCommandResponseThunkResponse,
  RedPiCommandsThunkResponse,
  RedPiDeviceThunkResponse,
  RedPiLogsThunkResponse
} from "../types/redux";
import { DateTime } from "luxon";
import { IDateRange } from "../types/time";
import {
  DEFAULT_PAGINATION,
} from "../constants/general";
import { fetchAlerts } from "../api/alert";
import { ICustomResponse } from "./../types/server";
import { fetchNotificationPermissions, fetchNotifications, putUpdateNotificationPermissions } from "../api/notification";
import { INotificationPermissions, INotificationPermissionUpdate } from "../types/notification";
import { getUserPersonalDetails } from "../api/user";
import { getAllChallengesForPlantID, getSpecificChallenge } from "../api/challenges";
import { GenericChallenge } from "../types/challenges";
import { objectContainsOfType } from "../util/type";
import { defaultFirebaseClaims } from "../constants/redux";
import { getClaims } from "../../auth";
import { PurchaseInfo, ProductId } from "./../types/products";
import { getPurchases } from "../api/purchases";
import { PlantTradingStatus } from "../types/trading";
import { deleteAccessoryScheduleById, fetchAccessoryByAccesoryId, fetchAccessoryStatus, fetchPlantAccessories, getAccessorySchedule, postAccessorySchedule } from "../api/accessory";
import { AccessoryId, AccessorySchedulePostBody, OCPPSchedule, PlantAccessory } from "../types/accessory";
import {
  fetchRedPiCommands,
  fetchRedPiDevices,
  fetchRedPiLogs,
  postRedPiCommand
} from "../api/redpi";
import { SoracomAirStats, SoracomSim, StatsPeriod, SimCommandRequest } from "../types/sim";
import { getSimData, getSimInfo, sendSimCommand } from "../api/sim";
import { deleteScheduleByScheduleId, getPlantScheduleByScheduleId, getPlantSchedules, postPlantSchedules, putPlantScheduleByScheduleId } from "../api/schedules";
import { ScheduleId, SchedulePlantCommandEvent, SchedulePlantCommandEventCreate, SchedulePlantCommandEventUpdate } from "../types/schedules";

export const fetchPriceDataThunkAction = createAsyncThunk<
  fetchPriceDataThunkResponse,
  IPriceDataThunkParams | undefined,
  { state: RootState, rejectValue: number }
>(
  "appData/getPriceData",
  async (args, { getState, rejectWithValue }) => {

    const currentId = getState().appData.currentPlantId;
    const chosenId: PlantId | undefined = args ? args.plantId : currentId;
    if (chosenId === undefined) return rejectWithValue(0);

    const params: IPriceDataThunkParams = args ? args : {
      plantId: chosenId,
      startUnixTimestamp: DateTime.now().toMillis(),
      endUnixTimestamp: DateTime.now().toMillis(),
      resolution: 5
    };
    const response = await fetchPriceDataForPlant(params);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const fetchPowerDataThunkAction =
  createAsyncThunk<fetchPowerDataThunkResponse,
    IPowerDataThunkParams | void,
    { state: RootState }>
    (
      "appData/getPowerData",
      async (args, { getState, rejectWithValue }) => {
        let targetIds: string[] = [];

        const thunkArgs: IPowerDataThunkParams = args ? args : {
          plantIds: null,
          startTime: DateTime.now().minus({ hours: 3 }),
          endTime: DateTime.now(),
          minuteInterval: 5
        };

        const currentPlantId = getState().appData.currentPlantId;

        if (Array.isArray(thunkArgs.plantIds)) targetIds = thunkArgs.plantIds;
        else if (thunkArgs.plantIds) targetIds.push(thunkArgs.plantIds);
        else if (!thunkArgs.plantIds && currentPlantId) targetIds.push(currentPlantId);

        if (targetIds.length === 0) return rejectWithValue(0);

        const body: IPlantGetDataV2Params = {
          plantIds: targetIds,
          startUnixTimestamp: thunkArgs.startTime.toMillis(),
          endUnixTimestamp: thunkArgs.endTime.toMillis(),
          minuteInterval: thunkArgs.minuteInterval,
        };

        const response = await fetchPlantData(body);

        if (response === null || !response.ok) {
          return rejectWithValue(response?.status ? response.status : 0);
        } else {
          return response["data"].data;
        }
      }
    );

export const financialDataThunk = createAsyncThunk<
  ServerResponse["v3"]["plants"]["{plantId}"]["data"]["financial"]["{interval}"]["GET"]["200"],
  {
    plantId?: Parameters<typeof fetchFinancialData>[0],
    interval?: Parameters<typeof fetchFinancialData>[1],
    queryParams?: Parameters<typeof fetchFinancialData>[2]
  } | undefined,
  { state: RootState }>
  (
    "appData/cachedFinancialData",
    async (args, { getState, rejectWithValue }) => {

      const id: string | undefined = args?.plantId ?? getState().appData.currentPlantId;
      if (!id) return rejectWithValue(0);

      const interval: CachedDataIntervals = args?.interval ?? CachedDataIntervals.Daily;

      const queryParams: Parameters<typeof fetchFinancialData>[2] =
        args?.queryParams ??
        {
          "endDate": DateTime.now().toFormat(UTC_DATE_FORMAT),
          "startDate": DateTime.now().toFormat(UTC_DATE_FORMAT),
        };

      const response = await fetchFinancialData(id, interval, queryParams);

      if (response === null || !response.ok) {
        return rejectWithValue(0);
      } else {
        return response.data;
      }
    }
  );

export const cumulativeDailyFinancialDataThunk = createAsyncThunk<
  ICumulativeDailyFinancialDataResponse["financialData"],
  IDateRange,
  { state: RootState }
>(
  "appData/cumulativeDailyFinancialData",
  async (bounds, { rejectWithValue }) => {

    const params: ICumultiveDailyDataParams = {
      startUnixTimestamp: bounds.startDateTime.toMillis(),
      endUnixTimeStamp: bounds.endDateTime.toMillis(),
    };

    const response = await fetchCumulativeFinancialData(params);

    if (response === null || !response.ok) {
      return rejectWithValue([]);
    } else {
      return response.data.financialData;
    }
  });

export const environmentalDataThunk = createAsyncThunk<
  ServerResponse["v3"]["plants"]["{plantId}"]["data"]["environmental"]["{interval}"]["GET"]["200"],
  {
    plantId?: Parameters<typeof fetchEnvironmentalData>[0],
    interval?: Parameters<typeof fetchEnvironmentalData>[1],
    queryParams?: Parameters<typeof fetchEnvironmentalData>[2]
  } | undefined,
  { state: RootState }>
  (
    "appData/cachedEnvironmentalData",
    async (args, { getState, rejectWithValue }) => {

      const id: string | undefined = args?.plantId ?? getState().appData.currentPlantId;
      if (!id) return rejectWithValue(0);

      const interval: CachedDataIntervals = args?.interval ?? CachedDataIntervals.Daily;

      const queryParams: Parameters<typeof fetchEnvironmentalData>[2] =
        args?.queryParams ??
        {
          "endDate": DateTime.now().toFormat(UTC_DATE_FORMAT),
          "startDate": DateTime.now().toFormat(UTC_DATE_FORMAT),
        };

      const response = await fetchEnvironmentalData(id, interval, queryParams);

      if (response === null || !response.ok) {
        return rejectWithValue(0);
      } else {
        return response.data;
      }
    }
  );

export const cumulativeDailyEnvironmentalDataThunk = createAsyncThunk<
  ICumulativeEnvironmentalDataResponse["environmentalData"], IDateRange, { state: RootState }
>(
  "appData/cumulativeDailyEnvironmentalData",
  async (bounds, { rejectWithValue }) => {

    const params: ICumultiveDailyDataParams = {
      startUnixTimestamp: bounds.startDateTime.toMillis(),
      endUnixTimeStamp: bounds.endDateTime.toMillis(),
    };

    const response = await fetchCumulativeEnvironmentalData(params);

    if (response === null || !response.ok) {
      return rejectWithValue([]);
    } else {
      return response.data["environmentalData"];
    }
  }
);

export const fetchAggregatePlantDataThunkAction = createAsyncThunk<
  IAggregatePlantResponse, IDateRange, { state: RootState }>
  (
    "appData/getAggregatePlantData",
    async (args, { rejectWithValue }) => {

      const body: IAggregatePlantRequestParams = {
        startUnixTimestamp: args.startDateTime.toMillis(),
        endUnixTimestamp: args.endDateTime.toMillis(),
        minuteInterval: 5,
      };

      const response = await fetchAggregatePlantData(body);

      if (response === null || !response.ok) {
        return rejectWithValue(response?.status ? response.status : 0);
      } else {
        return response.data;
      }
    }
  );

export const fetchPlantsThunk = createAsyncThunk<
  IPlantThunkResponse,
  V3PlantsListQueryparams,
  { state: RootState }
>(
  "appData/fetchPlantsThunk",
  async (args, { getState, rejectWithValue }) => {
    /*
  Warning: the action logic for resolving this thunk is built on the
  assumption that the possible and demo plants are always called
  fetched along with the normal plants. If you change this thunk so
  it will not fetch possible or demo plants you must also
  change the action logic. You will need to make it aware that the
  demo/possible were not included so it doesn't overwrite the state
  with empty arrays.
  */

    const state: RootState = getState();

    args.showPossible = true;
    args.demo = state.appData.settings ? state.appData.settings.showDemoPlants : false;
    if (args.page === undefined) args.page = DEFAULT_PAGINATION.currentPage;
    if (args.limit === undefined) args.limit = DEFAULT_PAGINATION.numberPerPage;

    const response = await fetchUserPlants(args);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return {
        data: {
          plants: response.data.plants,
          possiblePlant: response.data.possiblePlants,
          demoPlants: response.data.demoPlants,
        },
        pagination: response.data.pagination
      };
    }
  });

export const updatePlantThunk = createAsyncThunk<
  IPlantInfoThunkResponse, PlantId | void, { state: RootState }>
  (
    "appData/updatePlantInfoThunk",
    async (arg, { getState, rejectWithValue }) => {
      const id = arg ? arg : getState().appData.currentPlantId;
      if (!id) return rejectWithValue(0);
      const response = await fetchPlantInfo(id);
      if (response === null || !response.ok) {
        return rejectWithValue(response?.status ? response.status : 0);
      } else {
        return {
          plantId: id,
          data: response.data,
        };
      }
    }
  );

export const getAlertListThunk = createAsyncThunk<
  IFetchAlertsResponse, IFetchAlertsQueryParams, { state: RootState }>(
    "appData/getAlertListThunk",
    async (arg, { rejectWithValue }) => {
      const response: ICustomResponse<IFetchAlertsResponse> | null = await fetchAlerts(arg);

      if (response !== null && response.ok) {
        return {
          pagination: response.data.pagination,
          plantIds: response.data.plantIds
        };
      }

      return rejectWithValue(0);
    }
  );

export const getNotificationsThunk = createAsyncThunk<
  IFetchNotificationsResponse, void, { state: RootState }>(
    "appData/getNotificationsThunk",
    async (_, { rejectWithValue }) => {
      const response: ICustomResponse<IFetchNotificationsResponse> | null =
        await fetchNotifications();
      if (response !== null && response.ok) {
        return response.data;
      }

      return rejectWithValue(0);
    }
  );

export const notificationPermissionsThunk = createAsyncThunk<
  INotificationPermissions, undefined | INotificationPermissionUpdate, { state: RootState }>(
    "appData/notificationPermissionsThunk",
    async (arg, { rejectWithValue }) => {
      const response = arg
        ? await putUpdateNotificationPermissions(arg)
        : await fetchNotificationPermissions();

      if (response !== null && response.ok) {
        return response.data;
      }

      return rejectWithValue(0);
    }
  );

export const getUserPersonalDetailsThunk = (
  createAsyncThunk<
    IUserPersonalDetails,
    void,
    { state: RootState }
  >(
    "appData/getUserPersonalDetailsThunk",
    async (_, { rejectWithValue }) => {
      const response = await getUserPersonalDetails();
      if (response) {
        return response.data;
      }
      return rejectWithValue(null);
    }
  )
);

export const getChallengesForPlantThunk = (
  createAsyncThunk<
    Array<GenericChallenge>,
    {
      plantId: string
    } | undefined,
    { state: RootState }
  >(
    "appData/getChallengesThunk",
    async (arg, { getState, rejectWithValue }) => {

      const targetID = arg ? arg.plantId : getState().appData.currentPlantId;
      if (targetID === undefined) return rejectWithValue(0);

      const response = await getAllChallengesForPlantID(targetID);

      if (response) {
        return response.data;
      } else {
        return rejectWithValue(0);
      }
    }
  )
);

export const getSpecificChallengeThunk = createAsyncThunk<
  GenericChallenge[],
  {
    challengeId: string,
    plantId?: string,
  },
  { state: RootState }
>(
  "appData/getSpecificChallengeThunk",
  async (arg, { getState, rejectWithValue }) => {
    const currentPlantId = getState().appData.currentPlantId;
    if (
      currentPlantId === undefined ||
      arg.challengeId === undefined
    ) return rejectWithValue(0);

    const response = await getSpecificChallenge(arg.challengeId, currentPlantId);

    if (response && response.ok) {
      return response["data"];
    } else {
      return rejectWithValue(0);
    }
  }
);

export const getTradingHistoryThunk = createAsyncThunk<
  ServerResponse["v3"]["plants"]["{plantId}"]["trading"]["history"]["200"],
  {
    plantId?: PlantId,
    startDateTime: DateTime,
    endDateTime: DateTime
  },
  { state: RootState }
>(
  "appData/getTradeHistoryThunk",
  async (arg, { getState, rejectWithValue }) => {

    const targetId = arg.plantId ?? getState().appData.currentPlantId;

    if (!targetId || !arg.endDateTime || !arg.startDateTime) return rejectWithValue(0);

    const response = await fetchTradingHistory({
      "plantId": targetId,
      "endTimestamp": arg.endDateTime.toMillis(),
      "startTimestamp": arg.startDateTime.minus({ minutes: 45 }).toMillis(),
    });

    if (response?.ok) {
      return response.data;
    } else {
      return rejectWithValue(0);
    }
  }
);

export const getFirebaseUserClaimsThunkAction = createAsyncThunk<
  IFirebaseClaims, void, { state: RootState }>
  (
    "appData/getFirebaseClaims", async (arg, { rejectWithValue }) => {

      const claims = await getClaims();

      if (claims === null) rejectWithValue(0);
      // Note: the claims also include a lot of other info
      const keyInternalUser = "isInternalUser";

      const internalUser: boolean =
        (
          objectContainsOfType(claims, keyInternalUser, "boolean") &&
          claims !== null
        )
          ? claims[keyInternalUser] as boolean
          : defaultFirebaseClaims.internalUser;

      return {
        internalUser: internalUser
      };
    }
  );

export const getProductsThunk = createAsyncThunk<
  PurchaseInfo[], ProductId | undefined, { state: RootState }>(
    "appData/getProductsThunks",
    async (arg, { rejectWithValue }) => {
      const response = await getPurchases(arg);

      if (response?.ok) {
        return response.data;
      } else {
        return rejectWithValue(0);
      }
    }
  );

export const getTradingStatusThunk = createAsyncThunk<
  { viewId: string, data: PlantTradingStatus },
  PlantId | undefined, { state: RootState }>(
    "appData/getTradingStatusThunk",
    async (plantId, { rejectWithValue, getState }) => {

      const targetId = plantId ?? getState().appData.currentPlantId;
      if (targetId === undefined) {
        return rejectWithValue(0);
      }

      const response = await fetchTradingStatus({
        "plantId": targetId
      });

      if (response?.ok) {

        return {
          viewId: targetId,
          data: response.data
        };
      } else {
        return rejectWithValue(0);
      }
    }
  );

export const getAccessoryThunk = createAsyncThunk<
  {
    plantId: PlantId,
    data: PlantAccessory[],
    isEntireListForPlant: boolean,
  },
  {
    plantId?: PlantId,
    accessoryId?: AccessoryId,
  } | undefined,
  {
    state: RootState
  }>(
    "appData/getAccessoryThunk",
    async (args, { rejectWithValue, getState }) => {

      const targetId = args?.plantId ?? getState().appData.currentPlantId;
      if (!targetId) return rejectWithValue(0);

      const targetPlant = getState().appData.plants.data[targetId];
      const targetPlantPhysicalId = targetPlant.physicalPlantId;
      if (targetPlantPhysicalId === undefined) return rejectWithValue(0);

      let resultArray: PlantAccessory[] = [];

      if (args?.accessoryId) {
        //do a call to that specific id

        const res = await fetchAccessoryByAccesoryId({
          "accessoryId": args.accessoryId,
          "plantId": targetPlantPhysicalId,
        });
        if (res?.ok && res.data) {
          resultArray.push(res.data);
        } else return rejectWithValue(0);

      } else {
        // do the plant call for ids
        const res = await fetchPlantAccessories({
          "plantId": targetPlantPhysicalId
        });

        if (res?.ok && res?.data) {
          resultArray = res.data;
        } else return rejectWithValue(0);
      }

      return {
        isEntireListForPlant: args?.accessoryId === undefined,
        plantId: targetId,
        data: resultArray,
      };
    }
  );

export const getAccessoryStatusThunk = createAsyncThunk<
  {
    plantId: PlantId,
    accessoryId: AccessoryId,
    data: ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["status"]["GET"]["200"]
  },
  {
    plantId?: PlantId,
    accessoryId: AccessoryId,
  },
  {
    state: RootState
  }>(
    "appData/getAccessoryStatusThunk",
    async (args, { rejectWithValue, getState }) => {

      const targetId = args?.plantId ?? getState().appData.currentPlantId;
      if (!targetId) return rejectWithValue(0);

      const targetPlant = getState().appData.plants.data[targetId];
      const targetPlantPhysicalId = targetPlant.physicalPlantId;
      if (targetPlantPhysicalId === undefined) return rejectWithValue(0);

      // console.log("go for", args.accessoryId, targetPlantId);
      const res = await fetchAccessoryStatus({
        "accessoryId": args.accessoryId,
        "plantId": targetPlantPhysicalId,
      });

      // console.log("res", res);

      if (res?.ok && res.data) {
        return {
          plantId: targetId,
          accessoryId: args.accessoryId,
          data: res.data
        };
      } else return rejectWithValue(0);

    }
  );
export const fetchRedPiDevicesThunk = createAsyncThunk<
  RedPiDeviceThunkResponse,
  GetRedPiDevicesQueryParams,
  { state: RootState }
>(
  "appData/fetchRedPiDevicesThunk",
  async (args, { rejectWithValue }) => {
    const response = await fetchRedPiDevices(args);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const fetchRedPiCommandsThunk = createAsyncThunk<
  RedPiCommandsThunkResponse,
  undefined,
  { state: RootState }
>(
  "appData/fetchRedPiCommandsThunk",
  async (args, { rejectWithValue }) => {
    const response = await fetchRedPiCommands();

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const fetchRedPiLogsThunk = createAsyncThunk<
  RedPiLogsThunkResponse,
  GetRedPiLogsQueryParams,
  { state: RootState }
>(
  "appData/fetchRedPiLogsThunk",
  async (args, { rejectWithValue }) => {
    const response = await fetchRedPiLogs(args);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const postRedPiCommandThunk = createAsyncThunk<
  RedPiCommandResponseThunkResponse | undefined,
  PostRedPiCommandBody,
  { state: RootState }
>(
  "appData/postRedPiCommandThunk",
  async (args, { rejectWithValue }) => {
    const response = await postRedPiCommand(args.id, args.body);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const getSimInfoThunk = createAsyncThunk<
SoracomSim,
{ simId: string },
{ state: RootState }
>(
  "appData/getSimInfoThunk",
  async (args, { rejectWithValue }) => {
    const response = await getSimInfo(args.simId);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const getSimDataThunk = createAsyncThunk<
SoracomAirStats[],
{
  simId: string,
  from: DateTime,
  to: DateTime,
  period: StatsPeriod,
  imsi?: string,
},
{ state: RootState }
>(
  "appData/getSimDataThunk",
  async (args, { rejectWithValue }) => {
    const response = await getSimData(
      args.simId,
      args.from,
      args.to,
      args.period,
      args.imsi,
    );

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

export const sendSimCommandThunk = createAsyncThunk<
SoracomSim,
{ simId: string, command: SimCommandRequest },
{ state: RootState }
>(
  "appData/sendSimCommandThunk",
  async (args, { rejectWithValue }) => {
    const response = await sendSimCommand(args.simId, args.command);

    if (response === null || !response.ok) {
      return rejectWithValue(response?.status ? response.status : 0);
    } else {
      return response.data;
    }
  }
);

/**
 * This thunk does everything and more for schedules. Get a list of schedules
 * get a specific schedule, update a schedule or create a schedule.
 *
 * All routes will return either an array or a full schedule event object.
 * This will be passed into the redux store. So any action will basically result
 * in fresh objects for the specific range or change being made.
 *
 * @param {Object} action the action this thunk will be taking
 */
export const fetchSchedulesThunk = createAsyncThunk<
  {
    action: "get" | "update" | "create" | "delete"
    targetPlantId: PhysicalPlantId,
    data:SchedulePlantCommandEvent[] | SchedulePlantCommandEvent | undefined,
    scheduleIdThatWasDeleted?: ScheduleId,
  },
  {
    plantId?: PhysicalPlantId
  } & ({
    "action":"get",
    "scheduleId"?:ScheduleId
  } | {
    "action":"update",
    "scheduleId":ScheduleId,
    "body":SchedulePlantCommandEventUpdate
  } | {
    "action":"create",
    "body": SchedulePlantCommandEventCreate
  } | {
    "action": "delete",
    "scheduleId":ScheduleId
  }
  ),
  {
    state: RootState,
    rejectValue: ICustomResponse<ErrorBody> | undefined,
  }
    >(
    "appData/getSchedulesThunk",
    async (args, {rejectWithValue, getState}) => {

      let targetId = args?.plantId;

      if(targetId === undefined) {
        const state = getState();
        const currentPlantId = state.appData.currentPlantId;
        if(currentPlantId === undefined) {
          return rejectWithValue(undefined);
        }
        const currentPlant = state.appData.plants.data[currentPlantId];
        if(currentPlant === undefined || currentPlant.physicalPlantId === undefined) {
          return rejectWithValue(undefined);
        } else {
          targetId = currentPlant.physicalPlantId;
        }
      }

      let res: null |
      undefined |
      Awaited<ReturnType<typeof getPlantSchedules>> |
      Awaited<ReturnType<typeof getPlantScheduleByScheduleId>> |
      Awaited<ReturnType<typeof putPlantScheduleByScheduleId>> |
      Awaited<ReturnType<typeof postPlantSchedules>> |
      Awaited<ReturnType<typeof deleteScheduleByScheduleId>>
      ;

      if(args.action === "get") {
        res = args.scheduleId === undefined
          ? await getPlantSchedules(targetId)
          : await getPlantScheduleByScheduleId(args.scheduleId);
      } else if (args.action === "update") {
        res = await putPlantScheduleByScheduleId(args.scheduleId, args.body);
      } else if (args.action === "delete") {
        res = await deleteScheduleByScheduleId(args.scheduleId);
      } else if (args.action === "create") {
        res = await postPlantSchedules(args.body);
      }

      if(res?.ok) {
        return {
          action: args.action,
          targetPlantId: targetId,
          data: res.data,
          //provide the schedule we deleted if we did a delete action
          scheduleIdThatWasDeleted: args.action === "delete" ? args.scheduleId : undefined
        };
      } else return rejectWithValue(res as unknown as ICustomResponse<ErrorBody>);
    }
    );

type fetchScedulesThunkActions = "get" | "create" | "delete" | "update";

export const fetchAccessorySchedulesThunk = createAsyncThunk<
{
  action: fetchScedulesThunkActions,
  data: OCPPSchedule[],
  scheduleIdThatWasDeleted?: ScheduleId,
},
{
  accessoryId: AccessoryId,
  physicalPlantId: PhysicalPlantId,
  plantId: PlantId,
} & ({
  action: "get",
  // scheduleId?:ScheduleId // TODO: uncomment to add specific get for a schedule
} | {
  action: "create",
  body: AccessorySchedulePostBody
} | {
  action: "delete",
  scheduleIds:ScheduleId[]
} | {
  action:"update",
  body: AccessorySchedulePostBody,
  scheduleIds: ScheduleId[],
}),
{
  state: RootState,
  rejectValue: ICustomResponse<ErrorBody> | undefined,
}
  >("appData/fetchAccessorySchedulesThunk", async (args, {rejectWithValue}) => {
    const {accessoryId, action, physicalPlantId} = args;

    let res: null |
      Awaited<ReturnType<typeof getAccessorySchedule>> |
      Awaited<ReturnType<typeof postAccessorySchedule>> = null;

    switch (args.action) {
      case "get":
        res = await getAccessorySchedule({ accessoryId, physicalPlantId });
        break;
      case "create":
        res = await postAccessorySchedule({ physicalPlantId, accessoryId, body: args.body });
        if(!res || !res.ok) {
          return rejectWithValue(res as unknown as ICustomResponse<ErrorBody> ?? undefined);
        }
        res = await getAccessorySchedule({ accessoryId, physicalPlantId });
        break;
      case "delete":
      case "update":
        // eslint-disable-next-line no-case-declarations
        const deleteResponse = await Promise.allSettled(
          args.scheduleIds.map((scheduleId) => (
            deleteAccessoryScheduleById({ accessoryId, physicalPlantId, scheduleId })
          ))
        );
        for (let index = 0; index < deleteResponse.length; index++) {
          const settled = deleteResponse[index];
          if(settled.status === "rejected") return rejectWithValue(undefined);
          if(!settled.value || !settled.value.ok) {
            return rejectWithValue(settled.value as unknown as ICustomResponse<ErrorBody> ?? undefined);
          }
        }
        if(args.action === "update") {
          res = await postAccessorySchedule({ physicalPlantId, accessoryId, body: args.body });
          if(!res || !res.ok) {
            return rejectWithValue(res as unknown as ICustomResponse<ErrorBody> ?? undefined);
          }
        }
        res = await getAccessorySchedule({ accessoryId, physicalPlantId});
        break;
      default:
        return rejectWithValue(undefined);
    }

    if(!res || !res.ok) {
      return rejectWithValue(res as unknown as ICustomResponse<ErrorBody> ?? undefined);
    }

    const {data} = res;

    return {
      action,
      data,
      scheduleIdThatWasDeleted: undefined
    };
  });