import { ScheduleDays } from "../types/accessory";

export const accessoryTimeFormat = "HH:mm";

export const scheduleDaysToUILabel: Record<ScheduleDays, string> = {
  [ScheduleDays.Monday]: "Mon",
  [ScheduleDays.Tuesday]: "Tues",
  [ScheduleDays.Wednesday]: "Wed",
  [ScheduleDays.Thursday]: "Thurs",
  [ScheduleDays.Friday]: "Fri",
  [ScheduleDays.Saturday]: "Sat",
  [ScheduleDays.Sunday]: "Sun",
  [ScheduleDays.Daily]: "Daily"
};