/* eslint-disable max-len */
import { request } from ".";
import {
  AccessoryCommandRequest,
  AccessoryId,
  AccessorySchedulePostBody,
} from "../types/accessory";
import { PhysicalPlantId, PlantId } from "../types/plant";
import { ScheduleId } from "../types/schedules";
import { RequestResult, ServerResponse } from "../types/server";

export const fetchPlantAccessories = ({
  plantId,
}: {
  plantId: PlantId;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["GET"]["200"]
> => {
  return request(
    `v3/plants/${plantId}/accessories`,
    "GET",
    undefined,
    undefined,
    // TODO: add the query param for accessory type once
    // v3 changes are in this branch
  );
};

//TODO: create plant accessory. post of above

export const fetchAccessoryByAccesoryId = ({
  accessoryId,
  plantId,
}: {
  accessoryId: AccessoryId;
  plantId: PlantId;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["GET"]["200"]
> => {
  return request(`v3/plants/${plantId}/accessories/${accessoryId}`, "GET");
};

export const editAccessoryByAccessoryId = ({
  plantId,
  accessoryId,
  fieldsToEdit,
}: {
  plantId: PlantId;
  accessoryId: AccessoryId;
  fieldsToEdit: {
    type?: string;
    provider?: string;
    providerDeviceId?: string;
    providerDeviceName?: string;
    providerDeviceChildId?: string | null;
    displayName?: string | null;
    installDate?: number;
  };
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["PUT"]["200"]
> => {
  return request(`v3/plants/${plantId}/accessories/${accessoryId}`, "PUT", {
    ...fieldsToEdit,
  });
};

export const fetchAccessoryStatus = ({
  plantId,
  accessoryId,
}: {
  plantId: PlantId;
  accessoryId: AccessoryId;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["status"]["GET"]["200"]
> => {
  return request(
    `v3/plants/${plantId}/accessories/${accessoryId}/status`,
    "GET",
  );
};

export const giveAccessoryCommand = ({
  physicalPlantId,
  accessoryId,
  body,
}: {
  physicalPlantId: PhysicalPlantId;
  accessoryId: AccessoryId;
  body: AccessoryCommandRequest;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["command"]["POST"]
> => {
  return request(
    `v3/plants/${physicalPlantId}/accessories/${accessoryId}/command`,
    "POST",
    body,
  );
};

export function getAccessorySchedule({
  physicalPlantId,
  accessoryId,
}: {
  physicalPlantId: PhysicalPlantId;
  accessoryId: AccessoryId;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["schedules"]["GET"]["200"]
> {
  return request(
    `v3/plants/${physicalPlantId}/accessories/${accessoryId}/schedules`,
    "GET",
  );
}

export function postAccessorySchedule({
  physicalPlantId,
  accessoryId,
  body,
}: {
  physicalPlantId: PhysicalPlantId;
  accessoryId: AccessoryId;
  body: AccessorySchedulePostBody;
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["schedules"]["POST"]["200"]
> {
  return request(
    `v3/plants/${physicalPlantId}/accessories/${accessoryId}/schedules`,
    "POST",
    body,
  );
}

export function deleteAccessoryScheduleById({
  physicalPlantId,
  accessoryId,
  scheduleId
}: {
  physicalPlantId: PhysicalPlantId,
  accessoryId: AccessoryId,
  scheduleId: ScheduleId,
}): RequestResult<
ServerResponse["v3"]["plants"]["{plantId}"]["accessories"]["{accessoryId}"]["schedules"]["{scheduleId}"]["DELETE"]
> {
  return request(`v3/plants/${physicalPlantId}/accessories/${accessoryId}/schedules/${scheduleId}`, "DELETE");
}