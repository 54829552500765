import { LabelledTimeRange } from "../util/time";
import { DateTime } from "luxon";
import { UnixTimeStamp } from "./redux";
import { PhysicalPlantId } from "./plant";
import { Schema } from "jsonschema";
import { UserId } from "./user";

const VALID_DAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

/**
 *
 * If !== SelfConsumption then is required
 */
export type OrderDirection =
  "None" |
  "MaximiseImports" |
  "ChargeBattery" |
  "ChargeBatteryBanExports" |
  "SelfConsumption" |
  "SelfConsumptionBanExports" |
  "MaximiseExports" |
  "NoData" |
  "TrickleCharge" |
  "MaximiseImportsBanExports" |
  "MaximiseExportsBatteryPassive";

export type ParameterType =
  "None" |
  "BatteryStop" |
  "BatteryDischarge" |
  "BatteryCharge" |
  "BatteryPowerTarget" |
  "ExportTargetEnable" |
  "ExportTargetPower" |
  "ExportTargetPercentage";

export type PlantOrder = {
  parameterType: ParameterType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any; //could be a number, boolean or string or anything
};

/**
 *
 * Trading order is the recipe we provide the inverter provider
 *
 */
export type TradingOrder = {
  orderDirection: OrderDirection;
  orders: PlantOrder[];
  timestamp?: number;
  plantId?: string;
  reason?: string;
};

/*
success is always constant. But random depending on inverter providers result
*/
type SuccessObject = {
  success: {
    task_id: number,
    task_name: string,
    param_list: SungrowParamList[] | DeyeParamList[]
  }[],
} | null;

type SungrowParamList = {
  unit: string,
  set_value: string,
  param_code: string,
  create_time: string, // ISO Date String
  command_status: number, // // 1 = Waiting, 2 = In progress, 4 = Successful, 5 = Failed, 6 = Timeout
  // There are other values we don't use.
}

type DeyeParamList = {
  touDays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
  gridChargeAction?: "on" | "off";
  gridChargeAmpere?: number;
  maxSellPower?: number;
  maxSolarPower?: number;
  solarSellAction?: "on" | "off";
  timeUseSettingItems?: DeyeTimeOfUseSettings[];
  touAction?: "on" | "off";
  workMode?: "SELLING_FIRST" | "ZERO_EXPORT_TO_LOAD" | "ZERO_EXPORT_TO_CT";
  zeroExportPower?: number;
}

type DeyeTimeOfUseSettings = {
  enableGeneration: boolean;
  enableGridCharge: boolean;
  power: number;
  soc: number;
  time: string;
  voltage: number;
}

export function isDeyeParamList(list: unknown): list is DeyeParamList[] {
  return (
    list &&
    Array.isArray(list) &&
    list.length > 0 &&
    "touDays" in list[0] &&
    Array.isArray(list[0].touDays) &&
    list[0].touDays.every((day: string) => VALID_DAYS.includes(day))
  );
}

export type TradingHistory = {
  timestamp: UnixTimeStamp,
  completedSuccessfully: boolean,
  success: SuccessObject,
  trade: TradingOrder
}

export type PlantTradingStatus = {
  canTrade: boolean,
  reason?: string,
  hasValidAmberKey: boolean,
  hasValidDataSource: boolean,
  tradingManuallyPermitted: boolean,
  isWholesale: boolean,
  tradingProviderCode: number,
  batteryCount: number,
  batteryCapacity: number,
  installedSolarCapacity: number,
}

/**
 *
 * completedSuccessfully == false && success === null then didn't check
 *      treat as success
 *
 * completedSuccessfully == false && success is something then outright fail
 *
 *
 * completedSuccessfully == true then outright success
 *
 */

export type TradingChoices = {
  successfulTrades: LabelledTimeRange[],
  tradingCommandFails: DateTime[],
  stopTradingCommandFails: DateTime[]
}

export enum TradeHistoryFidelity {
  //don't take account of any of the fail
  NoFails = "no_fails",
  /*
  a consequential fail is when a command fails to change the current algo behaviour.
  E.g:
  - failing to to maximize exports when maximize exports is already happening is
  inconsequential because if it succeeded nothing would change.
  - failing to set to selfConsumption while algo is currently maximizing imports
  is consequential because the fail means the plant acts differently to what we wanted
  */
  ConsequentialFails = "consequential_fails",
  //take account of all the possible failures regardless of consequence
  AllFails = "all_fails",
}
export enum TradingAlgorithms {
  SelfSufficiency = "self_sufficiency",
  AggressiveExports = "aggressive_exports"
}

export enum PlantUserCommands {
  TopUpBattery = "top-up-battery",
  EmptyBattery = "empty-battery"
}

export type UserOverrideCommand = {
  order: TradeOrder,
  userId?: UserId,
  instruction: PlantUserCommands, // "top-up-battery" | "empty-battery",
  start: UnixTimeStamp, //unixTimestamp
  expiry: UnixTimeStamp //unixTimestamp
}

export type TradingData = {
  nextExportPeak?: {
    startTime: number,
    endTime: number,
    export: number,
  }
};

export type TradeOrder = {
  plantId: PhysicalPlantId,
  orderDirection: number,
  orders: Array<{
    parameterType: number,
    value: number | boolean,
  }>,
  reason?: string
};

export type TradingMetadata = {
  tradingData?: TradingData,
  importTradingThreshold?: number,
  exportTradingThreshold?: number,
  importPriceSetPoint?: number,
  exportPriceSetPoint?: number,
}

export type UserOverrideCommandSchema = {
  id: PlantUserCommands,
  description: string,
  schema: Schema, //TODO add type of schema
}

export type TimeBasedCommandOptions = {
  startTime: number,
  endTime: number,
  [key: string]: unknown,
}

export type PutUserCommandBody = {
  [PlantUserCommands.EmptyBattery]: TimeBasedCommandOptions,
  [PlantUserCommands.TopUpBattery]: TimeBasedCommandOptions,
}
