import React, { useEffect, useState } from "react";
// Material UI core
import styles from "./energy.module.scss";

import {
  LabelledTimeRange,
  TIME_RANGES,
  UI_HOUR_FORMAT,
  UTC_DATE_FORMAT,
} from "apps-middleware/util/time";
import Loading from "components/Loading/Loading";

import { TimeScaleLineChart } from "components/Charts/TimeScaleLineChart";

import { Grid, Button, Typography, IconButton } from "@mui/material";
import {
  Restore,
  Refresh,
  Start,
  VisibilityRounded,
  VisibilityOffRounded,
  Filter,
} from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "apps-middleware/redux/store/hooks";
import { selectCurrentPlantId } from "apps-middleware/redux/selectors/plants";
import { IDateRange } from "apps-middleware/types/time";
import {
  DEFAULT_GRAPH_VISIBILITY_STATE,
  priceProfileDataKeys,
} from "constants/graph";
import { Graphs, PotentialDataKeys } from "types/graph";
import { DropdownDateRangePicker }
  from "components/Dropdowns/DropdownRangePicker/DropdownRangePicker";
import { getGraphDataForCurrentPlant } from "redux/graphing-selectors";
import { WarningText } from "components/WarningText/WarningText";
import {
  getEnergyChartOptions,
  getGraphVisibilityForPlant,
  setEnergyChartOptions,
  setGraphVisibilityForPlant,
} from "util/localStorage";
import { PlantId } from "apps-middleware/types/plant";
import { UNITS } from "apps-middleware/constants/general";
import { DateTime } from "luxon";

import { usePlant } from "apps-middleware/hooks/usePlant";

import { useFinancialData } from "apps-middleware/hooks/useCachedData";
import { CachedDataIntervals } from "apps-middleware/types/server";
import {
  DEFAULT_ENTRY_HEIGHT,
  DropdownChecklist,
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { TradeHistoryFidelity } from "apps-middleware/types/trading";
import { tradingHistoryFidelityToSemanticName } from "apps-middleware/util/trading";
import { isPlantInfo } from "apps-middleware/util/type";
import AlertEntry from "components/AlertEntry/AlertEntry";

const GRAPH_HEIGHT = 330;

function GraphPage(): JSX.Element | null {
  const currentPlantId = useAppSelector(selectCurrentPlantId);

  if (currentPlantId) {
    return <PageBody id={currentPlantId} />;
  } else {
    return null;
  }
}

function PageBody({ id }: { id: PlantId }): JSX.Element | null {
  const navigate = useNavigate();
  const location = useLocation();

  //default values are since midnight
  const [plotBounds, setPlotBoundsTrue] = useState<LabelledTimeRange>(
    TIME_RANGES.TODAY
  );
  const setPlotBounds = (arg: LabelledTimeRange) => {
    setZoomedDomain(null);
    setPlotBoundsTrue(arg);
  };

  const financialData = useFinancialData({
    plantId: id,
  });

  const plant = usePlant({
    plantId: id,
    defaultTradeHistoryFidelity: getEnergyChartOptions().tradeHistoryFidelity,
  });

  const { combinedData, dataKeysWithData } = useAppSelector(
    getGraphDataForCurrentPlant
  );

  const [epochOfLastUpdate, setEpochOfLastUpdate] = useState(DateTime.now());
  const [forceDataRefresh, setForceDataRefresh] = useState<number>(1);
  const refreshData = () => setForceDataRefresh(forceDataRefresh + 1);

  const [zoomedDomain, setZoomedDomain] = useState<IDateRange | null>(null);

  const [graphVisibility, setGraphVisibility] = useState<
    Record<Graphs, boolean>
  >(id ? getGraphVisibilityForPlant(id) : DEFAULT_GRAPH_VISIBILITY_STATE);
  const [showTradingLogsButton, setShowTradingLogsButton] = useState(false);

  useEffect(
    function collectDataIfNecessary() {
      //TODO: add a function to look through the data and check if
      //any actually needs to be gathered then gather if necessary
      // atm im just grabbing everything cause computers are cheaper than time
      if (!plant) return;

      plant.plantInfo.refresh();
      plant.powerData.refresh(plotBounds);
      plant.priceData.refresh(plotBounds);
      financialData.get(CachedDataIntervals.Daily, {
        startDate: plotBounds.range.startDateTime.toFormat(UTC_DATE_FORMAT),
        endDate: plotBounds.range.endDateTime.toFormat(UTC_DATE_FORMAT),
      });
      plant.tradingHistory.refresh(plotBounds);

      setEpochOfLastUpdate(DateTime.now());
    },
    [plotBounds, id, forceDataRefresh]
  );

  // Only show trading logs button if the user has trading logs (will also hide the button for users
  // that do not have permission to view logs).
  useEffect(() => {
    if (!plant) return;

    const tradeData = combinedData.filter(
      (row) =>
        row.unixTimestamp &&
        row.unixTimestamp > plotBounds.range.startDateTime.toMillis() &&
        row.unixTimestamp < plotBounds.range.endDateTime.toMillis() &&
        row.mostRecentCommand
    );
    if (tradeData.length === 0) {
      setShowTradingLogsButton(false);
    } else {
      setShowTradingLogsButton(true);
    }
  }, [plotBounds, combinedData]);

  function changeVisibility(newObj: Record<Graphs, boolean>): void {
    setGraphVisibility(newObj);
    if (id) setGraphVisibilityForPlant(id, newObj);
  }

  useEffect(
    function onTradingStatusFidelityChange() {
      if (plant && plant.tradingHistory.dataFidelity) {
        setEnergyChartOptions({
          ...getEnergyChartOptions(),
          tradeHistoryFidelity: plant.tradingHistory.dataFidelity,
        });
      }
    },
    [plant?.tradingHistory.dataFidelity]
  );

  function resetZoomedDomain() {
    setZoomedDomain(null);
  }

  function goToTradingLogs() {
    const state = {
      id: plant?.id,
      name: plant?.name,
      plantName: plant?.plantName,
      plantProviderName: plant?.plantProviderName,
    };
    navigate(`${location.pathname}/trading-logs`, { state });
  }

  const loadingComponent = <div style={{minHeight: "calc(100vh - 110px)",
    display: "flex", alignContent: "center"}}>
    <Loading informationText={"Fetching Data..."} /></div>;

  // Need to check plants fetching before the !currentPlant, as the current
  // plant might not be set till plants finish fetching and show the incorrect
  // screen pre-load
  if (!plant) {
    return loadingComponent;
  } else if (!combinedData) {
    return (
      <WarningText
        message="There was an error when loading the device."
        showNetworkWarning
      />
    );
  } else if (plant.powerData.isFetching || plant.priceData.isFetching) {
    return loadingComponent;
  }

  const commonGraphProps = {
    plotBounds: plotBounds.range,
    graphHeight: GRAPH_HEIGHT,
    zoomedDomain,
    setZoomedDomain,
    syncId: "ENERGY_CHARTS",
    dataKeysToConsider: dataKeysWithData,
    data: combinedData.filter(
      (row) =>
        row.unixTimestamp &&
        row.unixTimestamp > plotBounds.range.startDateTime.toMillis() &&
        row.unixTimestamp < plotBounds.range.endDateTime.toMillis()
    ),
  };

  const ChartContainer = ({
    dataKeys,
    children,
    chart,
    filterComponent,
    alwaysVisible = false,
  }: {
    children: React.ReactChild;
    dataKeys: PotentialDataKeys[];
    alwaysVisible?: boolean;
    chart: Graphs;
    filterComponent?: JSX.Element;
  }): JSX.Element | null => {
    if (
      alwaysVisible ||
      dataKeys.filter((dataKey) => dataKeysWithData.includes(dataKey)).length >
        0
    ) {
      return (
        <Grid container item xs={12} gap={1} justifyContent="center">
          <Grid container direction={"row"} alignItems={"center"}>
            <Grid
              item
              xs
              container
              alignContent={"center"}
              justifyContent={"center"}
            >
              <GraphTitle type={chart} />
            </Grid>
            {filterComponent && (
              <Grid item xs="auto">
                {filterComponent}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {graphVisibility[chart] ? children : null}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const GraphTitle = ({ type }: { type: Graphs }) => (
    <div style={{ display: "flex", gap: 10 }}>
      <Typography
        variant="h5"
        align="center"
        fontStyle={{ lineHeight: "unset" }}
      >
        {type}
      </Typography>
      <IconButton
        color="primary"
        aria-label="toggle graph visbility"
        component="span"
        onClick={() => {
          changeVisibility({
            ...graphVisibility,
            [type]: !graphVisibility[type],
          });
        }}
      >
        {graphVisibility[type] ? (
          <VisibilityRounded />
        ) : (
          <VisibilityOffRounded />
        )}
      </IconButton>
    </div>
  );

  const powerProfileDataKeys: Array<PotentialDataKeys> = [
    plant.gridConnected ? "gridPower" : "generatorPower",
    "batteryPower",
    "loadPower",
    "pvPower",
    "evPower",
    "pvFromAC",
    "pvFromDC",
  ];

  return (
    <Grid container className={styles.container}>
      {/* header */}
      <Grid
        spacing={1}
        container
        item
        xs={4}
        direction="column"
        justifyContent="flex-start"
        alignItems="start"
      >
        {/* Content for the left side can go here */}
        {showTradingLogsButton && (
          <Button
            onClick={goToTradingLogs}
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<Start />}
            sx={{ marginLeft: "10px" }}
          >
            Trading Logs
          </Button>
        )}
        <Grid item>
          <Button
            onClick={refreshData}
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<Refresh />}
          >
            Refresh Data
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {epochOfLastUpdate
              ? `Graphs last updated at: ${epochOfLastUpdate.toFormat(
                UI_HOUR_FORMAT
              )}`
              : "Unable to determine last time of update"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} alignContent={"center"} alignItems={"center"} style={{textAlign: "center"}}>
        <Typography variant={"h5"}>Your System's Historical Data</Typography>
      </Grid>
      {/* Content for the right side can go here */}
      <Grid item xs={4}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap={1}
        >
          <Grid
            container
            item
            xs={12}
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="flex-end"
            gap={1}
          >
            {zoomedDomain && (
              <Button
                onClick={resetZoomedDomain}
                style={{ marginRight: 10 }}
                variant="contained"
                color="info"
                startIcon={<Restore />}
              >
                Reset Zoom
              </Button>
            )}
            <DropdownDateRangePicker
              dateRange={plotBounds}
              setDateRange={setPlotBounds}
            />
          </Grid>
          <Grid item>
            <DropdownChecklist
              isRadio
              popUpSize={{
                height:
                  DEFAULT_ENTRY_HEIGHT *
                  Object.values(TradeHistoryFidelity).length,
                width: 400,
              }}
              items={Object.values(TradeHistoryFidelity).map((option) => ({
                name: tradingHistoryFidelityToSemanticName[option],
                value: option,
              }))}
              defaultSelected={[plant.tradingHistory.dataFidelity]}
              onSelectionChange={(items) => {
                if (items[0])
                  plant.tradingHistory.changeFidelity(
                    items[0].value as TradeHistoryFidelity
                  );
              }}
              labelText="Trade Order Visibility"
              icon={<Filter />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={5}
        flexDirection="column-reverse"
        flexWrap="nowrap"
      >
        {/*

                Why are these charts rendered in opposite order?
                The tooltip rendered when hovered over each chart is a child of the
                TimeScaleLineChart component. This means other charts on the same HTML
                level have an equal z-index and the tooltip is rendered under any
                later siblings. So instead render the "top" graph last so it has the
                highest sibling z-index then render in reverse so the UI appears correct.

        */}

        <ChartContainer
          chart={Graphs.loadVoltageCurrent}
          dataKeys={["loadVoltage", "loadCurrent"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["loadVoltage"],
              label: UNITS.VOLT_LONG,
            }}
            rightYAxis={{
              dataKey: ["loadCurrent"],
              label: UNITS.AMP_LONG,
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.generatorFrequency}
          dataKeys={["generatorFrequency"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["generatorFrequency"],
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.generatorVoltageCurrent}
          dataKeys={["generatorVoltage", "generatorCurrent"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["generatorVoltage"],
              label: UNITS.VOLT_LONG,
            }}
            rightYAxis={{
              dataKey: ["generatorCurrent"],
              label: UNITS.AMP_LONG,
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.batteryVoltageCurrent}
          dataKeys={["batteryVoltage", "batteryCurrent"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["batteryVoltage"],
              label: UNITS.VOLT_LONG,
            }}
            rightYAxis={{
              dataKey: ["batteryCurrent"],
              label: UNITS.AMP_LONG,
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.pvVoltageCurrent}
          dataKeys={["pvVoltage", "pvCurrent"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["pvVoltage"],
              label: UNITS.VOLT_LONG,
            }}
            rightYAxis={{
              dataKey: ["pvCurrent"],
              label: UNITS.AMP_LONG,
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.batteryPercentage}
          dataKeys={["reportedBatterySoc", "calculatedBatterySoc"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["reportedBatterySoc", "calculatedBatterySoc"],
              domain: [0, 100],
            }}
            {...commonGraphProps}
          />
        </ChartContainer>

        {plant.gridConnected && (
          <ChartContainer
            chart={Graphs.wholesalePrice}
            dataKeys={priceProfileDataKeys}
            alwaysVisible
          >
            <TimeScaleLineChart
              tradingChoices={plant.tradingHistory.tradingChoices}
              isLoading={plant.priceData.isFetching}
              leftYAxis={{
                dataKey: priceProfileDataKeys,
              }}
              {...commonGraphProps}
            />
          </ChartContainer>
        )}

        <ChartContainer
          chart={Graphs.evVoltageCurrency}
          dataKeys={["evVoltage", "evCurrent"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["evVoltage"],
              label: UNITS.VOLT_LONG,
            }}
            rightYAxis={{
              dataKey: ["evCurrent"],
              label: UNITS.AMP_LONG,
            }}
            {...commonGraphProps}
          />
        </ChartContainer>
        <ChartContainer
          chart={Graphs.energyProfile}
          dataKeys={powerProfileDataKeys}
          alwaysVisible
        >
          <TimeScaleLineChart
            tradingChoices={plant?.tradingHistory.tradingChoices}
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: powerProfileDataKeys,
            }}
            defaultHiddenDataKeys={["pvFromAC", "pvFromDC"]}
            {...commonGraphProps}
          />
        </ChartContainer>

        {isPlantInfo(plant) && plant.alerts && plant.alerts.length > 0 && (
          <Grid
            container
            item
            xs={12}
            gap={2}
            alignSelf={"center"}
            flexDirection="column"
            justifyContent="center"
          >
            <Grid marginLeft={"20px"} marginRight={"20px"}>
              {plant.alerts.map((alert, i) => (
                <AlertEntry plantId={plant.plantId} alert={alert} key={i} />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default GraphPage;
