import React, { SyntheticEvent, useEffect, useState } from "react";
// Material UI core
import styles from "./energy.module.scss";
import {
  LabelledTimeRange,
  TIME_RANGES,
  UI_HOUR_FORMAT,
  UTC_DATE_FORMAT,
} from "apps-middleware/util/time";
import Loading from "components/Loading/Loading";

import { TimeScaleLineChart } from "components/Charts/TimeScaleLineChart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  Divider,
  Stack,
  Modal,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Slider,
  Snackbar,
  Alert,
  SnackbarCloseReason,
} from "@mui/material";
import {
  Restore,
  Refresh,
  Start,
  VisibilityRounded,
  VisibilityOffRounded,
  Filter,
} from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "apps-middleware/redux/store/hooks";
import { selectCurrentPhysicalPlantId, selectCurrentPlantId }
  from "apps-middleware/redux/selectors/plants";
import { IDateRange } from "apps-middleware/types/time";
import {
  DEFAULT_GRAPH_VISIBILITY_STATE,
  priceProfileDataKeys,
} from "constants/graph";
import { Graphs, PotentialDataKeys } from "types/graph";
import { DropdownDateRangePicker } from
  "components/Dropdowns/DropdownRangePicker/DropdownRangePicker";
import { getGraphDataForCurrentPlant } from "redux/graphing-selectors";
import { WarningText } from "components/WarningText/WarningText";
import {
  getEnergyChartOptions,
  getGraphVisibilityForPlant,
  setEnergyChartOptions,
  setGraphVisibilityForPlant,
} from "util/localStorage";
import { PlantId } from "apps-middleware/types/plant";

import { DateTime } from "luxon";

import { usePlant } from "apps-middleware/hooks/usePlant";

import { useFinancialData } from "apps-middleware/hooks/useCachedData";
import {
  CachedDataIntervals,
  ErrorBody,
  ICustomResponse,
} from "apps-middleware/types/server";
import {
  DEFAULT_ENTRY_HEIGHT,
  DropdownChecklist,
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { TradeHistoryFidelity } from "apps-middleware/types/trading";
import { tradingHistoryFidelityToSemanticName } from "apps-middleware/util/trading";
import { ScheduleDayOptions, SchedulePlantCommandEvent,
  SchedulePlantCommandEventCreate, SchedulePlantCommandEventUpdate }
  from "apps-middleware/types/schedules";
import { fetchSchedulesThunk } from "apps-middleware/redux/asyncThunks";
import { getTodayAsDayProp } from "apps-middleware/util/schedules";
import useIsMounted from "apps-middleware/hooks/useIsMounted";

const GRAPH_HEIGHT = 330;

function EnergyTradingPage(): JSX.Element | null {
  const currentPlantId = useAppSelector(selectCurrentPlantId);

  if (currentPlantId) {
    return <PageBody id={currentPlantId} />;
  } else {
    return null;
  }
}

function PageBody({ id }: { id: PlantId }): JSX.Element | null {
  const navigate = useNavigate();
  const location = useLocation();

  //default values are since midnight
  const [plotBounds, setPlotBoundsTrue] = useState<LabelledTimeRange>(
    TIME_RANGES.TODAY
  );
  const setPlotBounds = (arg: LabelledTimeRange) => {
    setZoomedDomain(null);
    setPlotBoundsTrue(arg);
  };

  const financialData = useFinancialData({
    plantId: id,
  });

  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const currentPhysicalPlantId = useAppSelector(selectCurrentPhysicalPlantId);

  const plant = usePlant({
    plantId: id,
    defaultTradeHistoryFidelity: getEnergyChartOptions().tradeHistoryFidelity,
  });

  const { combinedData, dataKeysWithData } = useAppSelector(
    getGraphDataForCurrentPlant
  );

  const [epochOfLastUpdate, setEpochOfLastUpdate] = useState(DateTime.now());
  const [forceDataRefresh, setForceDataRefresh] = useState<number>(1);
  const refreshData = () => setForceDataRefresh(forceDataRefresh + 1);

  const [zoomedDomain, setZoomedDomain] = useState<IDateRange | null>(null);

  const [graphVisibility, setGraphVisibility] = useState<
    Record<Graphs, boolean>
  >(id ? getGraphVisibilityForPlant(id) : DEFAULT_GRAPH_VISIBILITY_STATE);
  const [showTradingLogsButton, setShowTradingLogsButton] = useState(false);

  useEffect(
    function collectDataIfNecessary() {
      //TODO: add a function to look through the data and check if
      //any actually needs to be gathered then gather if necessary
      // atm im just grabbing everything cause computers are cheaper than time
      if (!plant) return;

      plant.plantInfo.refresh();
      plant.powerData.refresh(plotBounds);
      plant.priceData.refresh(plotBounds);
      financialData.get(CachedDataIntervals.Daily, {
        startDate: plotBounds.range.startDateTime.toFormat(UTC_DATE_FORMAT),
        endDate: plotBounds.range.endDateTime.toFormat(UTC_DATE_FORMAT),
      });
      plant.tradingHistory.refresh(plotBounds);
      plant?.schedules.get();

      setEpochOfLastUpdate(DateTime.now());
    },
    [plotBounds, id, forceDataRefresh]
  );

  const [snackOpen, setSnackOpen] = useState(false);
  const [creationErrorMessage, setCreationErrorMessage] = useState("");

  // Show the Snackbar when there's an error message
  useEffect(() => {
    if (creationErrorMessage !== "") {
      setSnackOpen(true); // Show the Snackbar
    }
  }, [creationErrorMessage]);

  const handleClose = (event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setCreationErrorMessage(""); // Clear the error message state
  };

  const handleAlertClose = (event: SyntheticEvent) => {
    setSnackOpen(false);
    setCreationErrorMessage(""); // Clear the error message state
  };

  // Only show trading logs button if the user has trading logs (will also hide the button for users
  // that do not have permission to view logs).
  useEffect(() => {
    if (!plant) return;

    const tradeData = combinedData.filter(
      (row) =>
        row.unixTimestamp &&
        row.unixTimestamp > plotBounds.range.startDateTime.toMillis() &&
        row.unixTimestamp < plotBounds.range.endDateTime.toMillis() &&
        row.mostRecentCommand
    );
    if (tradeData.length === 0) {
      setShowTradingLogsButton(false);
    } else {
      setShowTradingLogsButton(true);
    }
  }, [plotBounds, combinedData]);

  function changeVisibility(newObj: Record<Graphs, boolean>): void {
    setGraphVisibility(newObj);
    if (id) setGraphVisibilityForPlant(id, newObj);
  }

  useEffect(
    function onTradingStatusFidelityChange() {
      if (plant && plant.tradingHistory.dataFidelity) {
        setEnergyChartOptions({
          ...getEnergyChartOptions(),
          tradeHistoryFidelity: plant.tradingHistory.dataFidelity,
        });
      }
    },
    [plant?.tradingHistory.dataFidelity]
  );

  function resetZoomedDomain() {
    setZoomedDomain(null);
  }

  function goToTradingLogs() {
    const state = {
      id: plant?.id,
      name: plant?.name,
      plantName: plant?.plantName,
      plantProviderName: plant?.plantProviderName,
    };
    navigate(`${location.pathname}/trading-logs`, { state });
  }

  const loadingComponent = (
    <div
      style={{
        minHeight: "calc(100vh - 110px)",
        display: "flex",
        alignContent: "center",
      }}
    >
      <Loading informationText={"Fetching Data..."} />
    </div>
  );

  // Need to check plants fetching before the !currentPlant, as the current
  // plant might not be set till plants finish fetching and show the incorrect
  // screen pre-load
  if (!plant) {
    return loadingComponent;
  } else if (!combinedData) {
    return (
      <WarningText
        message="There was an error when loading the device."
        showNetworkWarning
      />
    );
  } else if (plant.powerData.isFetching || plant.priceData.isFetching) {
    return loadingComponent;
  }

  const commonGraphProps = {
    plotBounds: plotBounds.range,
    graphHeight: GRAPH_HEIGHT,
    zoomedDomain,
    setZoomedDomain,
    syncId: "ENERGY_CHARTS",
    dataKeysToConsider: dataKeysWithData,
    data: combinedData.filter(
      (row) =>
        row.unixTimestamp &&
        row.unixTimestamp > plotBounds.range.startDateTime.toMillis() &&
        row.unixTimestamp < plotBounds.range.endDateTime.toMillis()
    ),
  };

  const ChartContainer = ({
    dataKeys,
    children,
    chart,
    filterComponent,
    alwaysVisible = false,
  }: {
    children: React.ReactChild;
    dataKeys: PotentialDataKeys[];
    alwaysVisible?: boolean;
    chart: Graphs;
    filterComponent?: JSX.Element;
  }): JSX.Element | null => {
    if (
      alwaysVisible ||
      dataKeys.filter((dataKey) => dataKeysWithData.includes(dataKey)).length >
        0
    ) {
      return (
        <Grid container item xs={12} gap={1} justifyContent="center">
          <Grid container direction={"row"} alignItems={"center"}>
            <Grid
              item
              xs
              container
              alignContent={"center"}
              justifyContent={"center"}
            >
              <GraphTitle type={chart} />
            </Grid>
            {filterComponent && (
              <Grid item xs="auto">
                {filterComponent}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {graphVisibility[chart] ? children : null}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const GraphTitle = ({ type }: { type: Graphs }) => (
    <div style={{ display: "flex", gap: 10 }}>
      <Typography
        variant="h5"
        align="center"
        fontStyle={{ lineHeight: "unset" }}
      >
        {type}
      </Typography>
      <IconButton
        color="primary"
        aria-label="toggle graph visibility"
        component="span"
        onClick={() => {
          changeVisibility({
            ...graphVisibility,
            [type]: !graphVisibility[type],
          });
        }}
      >
        {graphVisibility[type] ? (
          <VisibilityRounded />
        ) : (
          <VisibilityOffRounded />
        )}
      </IconButton>
    </div>
  );

  const powerProfileDataKeys: Array<PotentialDataKeys> = [
    plant.gridConnected ? "gridPower" : "generatorPower",
    "batteryPower",
    "loadPower",
    "pvPower",
    "evPower",
    "pvFromAC",
    "pvFromDC",
  ];

  const tradingCard = (
    <React.Fragment>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-around"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "150px",
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Trading Algorithm
            </Typography>
            <Typography variant="h6" gutterBottom>
              {plant?.tradingAlgorithmType === "self_sufficiency"
                ? "Self Sufficiency"
                : "Aggressive"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "150px",
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Trading Status
            </Typography>
            <Typography variant="h5" gutterBottom>
              {plant?.tradingEnabled ? "Enabled" : "Disabled"}
            </Typography>
          </div>
        </Stack>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-15px",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 20 }} color="text.primary">
            Trading Thresholds
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingBottom: "15px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "50px",
              }}
            >
              <Typography
                sx={{ fontSize: 11 }}
                color="text.secondary"
                gutterBottom
              >
                Export Threshold
              </Typography>
              <Typography sx={{ fontSize: 15 }} gutterBottom>
                {plant?.exportTradingThreshold + " cents"}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: 11 }}
                color="text.secondary"
                gutterBottom
              >
                Import Threshold
              </Typography>
              <Typography sx={{ fontSize: 15 }} gutterBottom>
                {plant?.importTradingThreshold + " cents"}
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </React.Fragment>
  );

  const ERRORS = {
    NO_TIME_SELECTED: "Must select a time.",
    UNKNOWN_CREATION_ERROR:"Unable to create this schedule. Please try again later.",
    CONFLICTING_SCHEDULE_TIME: "This schedule conflicts with a pre-existing one",
  };

  enum PlantUserCommands {
    TopUpBattery = "top-up-battery",
    EmptyBattery = "empty-battery"
  }

  const ScheduleList = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState<SchedulePlantCommandEvent>();

    const [command, setCommand] = useState<PlantUserCommands | undefined>
    (PlantUserCommands.TopUpBattery);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");

    const [isRecurring, setIsRecurring] = useState(false);
    const [loading, setLoading] = useState(false);
    const [batteryPercentage, setBatteryPercentage] = useState<number>(65);

    const handleEditPress = (schedule: SchedulePlantCommandEvent) => {
      setCurrentSchedule(schedule);
      setModalOpen(true);
      setStartTime(schedule.start);
      setEndTime(schedule.end);
      setBatteryPercentage(schedule.params?.maxSoc as number);
      setIsRecurring(schedule?.day === "daily");
      setCommand(schedule.instruction);
    };

    const handleDeletePress = async (schedule: SchedulePlantCommandEvent) => {
      if (schedule?.id === undefined) return;
      await dispatch(fetchSchedulesThunk({
        action: "delete",
        scheduleId: schedule.id,
      }));
    };

    const closeModal = () => {
      setModalOpen(false);
      setCurrentSchedule(undefined);
    };

    const handleFormSubmit = async () => {
      // Logic to handle form submission for editing or creating a schedule
      sendSchedule();
    };

    // Format DateTime for datetime-local input
    const formatDateTimeForInput = (dateTimeString: string) => {
      const dateTime = DateTime.fromISO(dateTimeString); // or fromFormat if the format is different
      return dateTime.toFormat("yyyy-MM-dd'T'HH:mm");
    };

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newStartTime = DateTime.fromFormat(e.target.value, "yyyy-MM-dd'T'HH:mm");
      setStartTime(newStartTime.toISO()); // or another string format if needed
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEndTime = DateTime.fromFormat(e.target.value, "yyyy-MM-dd'T'HH:mm");
      setEndTime(newEndTime.toISO());
    };

    const sendSchedule = async () => {

      if(
        currentPhysicalPlantId === undefined ||
        startTime === undefined ||
        endTime === undefined
      ) {
        return null;
      }

      const body: SchedulePlantCommandEventCreate | SchedulePlantCommandEventUpdate = {
        instruction: command,
        start: DateTime.fromISO(startTime).toFormat("HH:mm"),
        end: DateTime.fromISO(endTime).toFormat("HH:mm"),
        day: isRecurring ? "daily" : getTodayAsDayProp() as ScheduleDayOptions,
        expiryDate: isRecurring ? undefined : DateTime.fromISO(endTime).toMillis(),
        params: {
          minSoc: command === PlantUserCommands.EmptyBattery ? batteryPercentage : undefined,
          maxSoc: command === PlantUserCommands.TopUpBattery ? batteryPercentage : undefined
        }
      };

      setLoading(true);

      const res = await dispatch(fetchSchedulesThunk({
        "action":"update",
        "scheduleId": currentSchedule?.id as number,
        "body":body as SchedulePlantCommandEventUpdate
      }
      ));

      if(isMounted()) setLoading(false);

      if(res.meta.requestStatus === "fulfilled") {
        return res;
      } else if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
        const response = res.payload as ICustomResponse<ErrorBody>;

        // we only want to pass in the error message on accepted codes so we
        // don't have rogue server messages being passed into the UI.
        const greenLitCodes = [409];

        setCreationErrorMessage(greenLitCodes.includes(response.status)
          ? response.data.message : ERRORS.UNKNOWN_CREATION_ERROR);

      }
    };

    return (
      <div style={{ paddingTop: "10px" }}>
        {plant?.schedules !== undefined &&
          (plant.schedules.data !== undefined && plant.schedules.data.length > 0 ? (
            plant.schedules.data.map((schedule, i) => (
              <div key={i}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" component="div">
                    Instruction {i + 1} : {schedule.instruction}
                  </Typography>
                  <Box>
                    <IconButton aria-label="edit" onClick={() => handleEditPress(schedule)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeletePress(schedule)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="body2">
                    Day of Operation: {schedule.day}
                  </Typography>
                  <Typography variant="body2">
                    Time range: {schedule.start} - {schedule.end}
                  </Typography>
                  <Typography variant="body2">
                    Max SOC Set point: {schedule.params?.maxSoc}%
                  </Typography>
                  <Typography variant="body2">
                    Schedule Enabled: {schedule.enabled ? "Yes" : "No"}
                  </Typography>
                </Box>
              </div>
            ))
          ) : (
            <Typography sx={{ mt: 2 }}>No Commands Scheduled</Typography>
          ))}

        {/* Modal Component */}
        <Modal
          open={modalOpen}
          onClose={closeModal}
          aria-labelledby="schedule-command-modal"
          aria-describedby="modal-to-schedule-new-command"
        >
          <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant="h6" component="h2">
              {currentSchedule ? "Edit Schedule" : "Create Schedule"}
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
              <TextField
                fullWidth
                margin="normal"
                type="datetime-local"
                label="Start Time"
                value={formatDateTimeForInput(startTime)}
                onChange={handleStartTimeChange}
              />
              <TextField
                fullWidth
                margin="normal"
                type="datetime-local"
                label="End Time"
                value={formatDateTimeForInput(endTime)}
                onChange={handleEndTimeChange}
              />
              {command === PlantUserCommands.TopUpBattery &&
              <>
                <Typography gutterBottom>Battery Percentage: {batteryPercentage}%</Typography>
                <Slider
                  value={batteryPercentage}
                  onChange={(e, newValue) => setBatteryPercentage(newValue as number)}
                  aria-labelledby="battery-percentage-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={100}
                />
              </>}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRecurring}
                    onChange={(e) => setIsRecurring(e.target.checked)}
                  />
                }
                label="Recurring"
              />
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    );
  };

  const ModalList = () => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCommand(event.target.value as PlantUserCommands);
    };

    const now = DateTime.now();
    const tenMinutesLater = now.plus({ minutes: 10 });

    const [modalOpen, setModalOpen] = useState(false);
    const [command, setCommand] = useState<PlantUserCommands | undefined>
    (PlantUserCommands.TopUpBattery);
    const [startTime, setStartTime] = useState<DateTime>(now);
    const [endTime, setEndTime] = useState<DateTime>(tenMinutesLater);
    const [isRecurring, setIsRecurring] = useState(false);
    const [loading, setLoading] = useState(false);

    const [batteryPercentage, setBatteryPercentage] = useState<number>(65);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const sendSchedule = async () => {

      if(
        currentPhysicalPlantId === undefined ||
        startTime === undefined ||
        endTime === undefined
      ) {
        return null;
      }

      const body: SchedulePlantCommandEventCreate = {
        plantId: currentPhysicalPlantId,
        instruction: command as PlantUserCommands,
        start: startTime.toFormat("HH:mm"),
        end: endTime.toFormat("HH:mm"),
        day: isRecurring ? "daily" : getTodayAsDayProp() as ScheduleDayOptions,
        expiryDate: isRecurring ? undefined : endTime.toMillis(),
        params: {
          minSoc: command === PlantUserCommands.EmptyBattery ? batteryPercentage : undefined,
          maxSoc: command === PlantUserCommands.TopUpBattery ? batteryPercentage : undefined
        }
      };

      setLoading(true);

      const res = await dispatch(fetchSchedulesThunk({
        "action":"create",
        "body": body as SchedulePlantCommandEventCreate
      }
      ));

      if(isMounted()) setLoading(false);

      if(res.meta.requestStatus === "fulfilled") {
        return res;
      } else if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
        const response = res.payload as ICustomResponse<ErrorBody>;

        // we only want to pass in the error message on accepted codes so we
        // don't have rogue server messages being passed into the UI.
        const greenLitCodes = [409];

        setCreationErrorMessage(greenLitCodes.includes(response.status)
          ? response.data.message : ERRORS.UNKNOWN_CREATION_ERROR);

      }
    };

    // Format DateTime for datetime-local input
    const formatDateTimeForInput = (dateTime: DateTime) =>
      dateTime.toFormat("yyyy-MM-dd'T'HH:mm");

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newStartTime = DateTime.fromFormat(e.target.value, "yyyy-MM-dd'T'HH:mm");
      setStartTime(newStartTime);
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEndTime = DateTime.fromFormat(e.target.value, "yyyy-MM-dd'T'HH:mm");
      setEndTime(newEndTime);
    };

    return (
      <div>
        {plant?.schedules !== undefined &&
          (plant.schedules.data !== undefined &&
          plant.schedules.data.length > 0 ? (
              <>
                <ScheduleList />
                <Divider orientation="horizontal" style={{ marginTop: "10px" }} />
                <Button
                  onClick={openModal}
                  variant="contained"
                  style={{marginTop: "20px"}}
                >
                  Schedule Another Command
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ mt: 2 }}>No Commands Scheduled</Typography>
                <Button
                  onClick={openModal}
                  variant="contained"
                  style={{marginTop: "20px"}}
                >
                  Schedule Command
                </Button>
              </>
            ))}

        {/* Modal Component */}
        <Modal
          open={modalOpen}
          onClose={closeModal}
          aria-labelledby="schedule-command-modal"
          aria-describedby="modal-to-schedule-new-command"
        >
          <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant="h6" component="h2">
              Schedule New Command
            </Typography>
            {creationErrorMessage && (
              <Typography color="error">{creationErrorMessage}</Typography>
            )}
            <form noValidate autoComplete="off">
              <RadioGroup
                aria-label="command"
                name="command"
                value={command}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={PlantUserCommands.TopUpBattery}
                  control={<Radio />}
                  label="Top Up Battery"
                />
                <FormControlLabel
                  value={PlantUserCommands.EmptyBattery}
                  control={<Radio />}
                  label="Empty Battery"
                />
              </RadioGroup>
              <TextField
                fullWidth
                margin="normal"
                type="datetime-local"
                label="Start Time"
                value={formatDateTimeForInput(startTime)}
                onChange={handleStartTimeChange}
              />
              <TextField
                fullWidth
                margin="normal"
                type="datetime-local"
                label="End Time"
                value={formatDateTimeForInput(endTime)}
                onChange={handleEndTimeChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRecurring}
                    onChange={(e) => setIsRecurring(e.target.checked)}
                  />
                }
                label="Recurring"
              />
              {command === PlantUserCommands.TopUpBattery &&
              <>
                <Typography gutterBottom>Battery Percentage: {batteryPercentage}%</Typography>
                <Slider
                  value={batteryPercentage}
                  onChange={(e, newValue) => setBatteryPercentage(newValue as number)}
                  aria-labelledby="battery-percentage-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={100}
                />
              </>}
              <Button
                onClick={sendSchedule}
                disabled={loading}
                variant="contained"
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    );
  };

  const PowerRangerCard = () => {
    // State to control the modal
    const [open, setOpen] = useState(false);
    const [stormProtectionActive, setStormProtectionActive] =
                              useState<SchedulePlantCommandEvent | null>(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Modal style
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    // Get the current date and time
    const now = DateTime.now();
    const startOfDay = now.startOf("day");
    const endOfDay = now.endOf("day");

    const sendStormProtection = async () => {
      if( currentPhysicalPlantId === undefined) return null;

      const body: SchedulePlantCommandEventCreate = {
        plantId: currentPhysicalPlantId,
        instruction: PlantUserCommands.TopUpBattery,
        start: startOfDay.toFormat("HH:mm"),
        end: endOfDay.toFormat("HH:mm"),
        day: getTodayAsDayProp(),
        expiryDate: endOfDay.toMillis(),
        params: {
          maxSoc: 100
        }
      };

      const res = await dispatch(fetchSchedulesThunk({
        "action":"create",
        "body": body
      }
      ));

      if(res.meta.requestStatus === "fulfilled") {
        return res;
      } else if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
        const response = res.payload as ICustomResponse<ErrorBody>;

        // we only want to pass in the error message on accepted codes so we
        // don't have rogue server messages being passed into the UI.
        const greenLitCodes = [409];

        setCreationErrorMessage(greenLitCodes.includes(response.status)
          ? response.data.message : ERRORS.UNKNOWN_CREATION_ERROR);

      }
    };

    useEffect(() => {
      const checkSchedules = () => {
        // Ensure data is valid
        if (!Array.isArray(plant.schedules.data)) {
          return;
        }

        // Check all schedules
        for (const schedule of plant.schedules.data) {
          if (typeof schedule.start !== "string" || typeof schedule.end !== "string") {
            continue; // Skip invalid schedules
          }

          const isFullDay =
            schedule.start === "00:00" && schedule.end === "23:59";

          const isMaxSoc100 = schedule.params?.maxSoc === 100;

          if (isFullDay && isMaxSoc100) {
            setStormProtectionActive(schedule);
            break; // Exit loop early if condition met
          }
        }
      };

      checkSchedules();
    }, [plant.schedules.data]); // Dependency array

    const handleStormProtectionDelete = async () => {
      if (stormProtectionActive?.id === undefined) return;
      await dispatch(fetchSchedulesThunk({
        action: "delete",
        scheduleId: stormProtectionActive.id,
      }));
    };

    const powerRangerCard = (
      <Card variant="outlined" style={{ height: "176px"}}>
        <CardContent >
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="center"
            divider={<Divider orientation="vertical" flexItem />}
            style={{ height: "145px"}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Power Ranger Subscription
              </Typography>
              <Typography variant="h5" gutterBottom>
                {plant?.hasPowerRanger ? "Subscribed" : "Not Subscribed"}
              </Typography>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                View Active Commands
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                style={{ display: "flex", alignItems: "center" }}
                gutterBottom
              >
                Active Commands: <span style={{ fontSize: 18, fontWeight: "bold", marginLeft: 4}}>
                  {plant?.schedules?.data?.length ?? 0}
                </span>
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                style={{ display: "flex", alignItems: "center" }}
                gutterBottom
              >
                Storm Protection: <span style={{ fontSize: 18, fontWeight: "bold", marginLeft: 4 }}>
                  {stormProtectionActive ? "Enabled" : "Disabled"}
                </span>
              </Typography>
              {stormProtectionActive ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleStormProtectionDelete}
                >
                  Disable Storm Protection
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={sendStormProtection}
                >
                  Enable Storm Protection
                </Button>
              )}
            </div>
          </Stack>
        </CardContent>

        {/* Modal component */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <Typography variant="h5" component="h2">
              Active Power Ranger Commands
            </Typography>
            <ModalList />
          </Box>
        </Modal>
      </Card>
    );

    return powerRangerCard;
  };

  return (
    <Grid container className={styles.container}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000} // Duration to automatically hide the Snackbar
        onClose={handleClose}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {creationErrorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <h2 style={{ marginTop: "0px" }}>PPP Dashboard</h2>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} md={6} style={{ height: "180px", minWidth: "475px" }}>
          <Card variant="outlined">{tradingCard}</Card>
        </Grid>
        <Grid item xs={6} md={6} style={{ height: "180px", minWidth: "475px" }}>
          <PowerRangerCard />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          flexItem
          style={{ marginTop: "25px", marginBottom: "30px" }}
        />
      </Grid>
      {/* header */}
      <Grid
        spacing={1}
        container
        item
        xs={4}
        direction="column"
        justifyContent="flex-start"
        alignItems="start"
      >
        {/* Content for the left side can go here */}
        {showTradingLogsButton && (
          <Button
            onClick={goToTradingLogs}
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<Start />}
            sx={{ marginLeft: "10px" }}
          >
            Trading Logs
          </Button>
        )}
        <Grid item>
          <Button
            onClick={refreshData}
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<Refresh />}
          >
            Refresh Data
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {epochOfLastUpdate
              ? `Graphs last updated at: ${epochOfLastUpdate.toFormat(
                UI_HOUR_FORMAT
              )}`
              : "Unable to determine last time of update"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        alignContent={"center"}
        alignItems={"center"}
        style={{ textAlign: "center" }}
      >
        <Typography variant={"h5"}>Trading Logs</Typography>
      </Grid>
      {/* Content for the right side can go here */}
      <Grid item xs={4}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap={1}
        >
          <Grid
            container
            item
            xs={12}
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="flex-end"
            gap={1}
          >
            {zoomedDomain && (
              <Button
                onClick={resetZoomedDomain}
                style={{ marginRight: 10 }}
                variant="contained"
                color="info"
                startIcon={<Restore />}
              >
                Reset Zoom
              </Button>
            )}
            <DropdownDateRangePicker
              dateRange={plotBounds}
              setDateRange={setPlotBounds}
            />
          </Grid>
          <Grid item>
            <DropdownChecklist
              isRadio
              popUpSize={{
                height:
                  DEFAULT_ENTRY_HEIGHT *
                  Object.values(TradeHistoryFidelity).length,
                width: 400,
              }}
              items={Object.values(TradeHistoryFidelity).map((option) => ({
                name: tradingHistoryFidelityToSemanticName[option],
                value: option,
              }))}
              defaultSelected={[plant.tradingHistory.dataFidelity]}
              onSelectionChange={(items) => {
                if (items[0])
                  plant.tradingHistory.changeFidelity(
                    items[0].value as TradeHistoryFidelity
                  );
              }}
              labelText="Trade Order Visibility"
              icon={<Filter />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5} flexDirection="column" flexWrap="nowrap">
        {plant.gridConnected && (
          <ChartContainer
            chart={Graphs.wholesalePrice}
            dataKeys={priceProfileDataKeys}
            alwaysVisible
          >
            <TimeScaleLineChart
              tradingChoices={plant.tradingHistory.tradingChoices}
              isLoading={plant.priceData.isFetching}
              leftYAxis={{
                dataKey: priceProfileDataKeys,
              }}
              {...commonGraphProps}
            />
          </ChartContainer>
        )}
        <ChartContainer
          chart={Graphs.energyProfile}
          dataKeys={powerProfileDataKeys}
          alwaysVisible
        >
          <TimeScaleLineChart
            tradingChoices={plant?.tradingHistory.tradingChoices}
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: powerProfileDataKeys,
            }}
            defaultHiddenDataKeys={["pvFromAC", "pvFromDC"]}
            {...commonGraphProps}
          />
        </ChartContainer>

        <ChartContainer
          chart={Graphs.batteryPercentage}
          dataKeys={["reportedBatterySoc", "calculatedBatterySoc"]}
        >
          <TimeScaleLineChart
            isLoading={plant.powerData.isFetching}
            leftYAxis={{
              dataKey: ["reportedBatterySoc", "calculatedBatterySoc"],
              domain: [0, 100],
            }}
            {...commonGraphProps}
          />
        </ChartContainer>
      </Grid>
    </Grid>
  );
}

export default EnergyTradingPage;
